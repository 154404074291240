import React, { useEffect, useState } from "react";
import { deleteChequeById, getChequesByCode } from "../../Services/Pointeuse/ChequeApi";
import { filterTeletravailByWord } from "../../Utils/SortObject";
import swal from "sweetalert";
import { t } from "i18next";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import { getSomme } from "../../Utils/ObjectFilter";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ExportCsv from "../Exports/ExportCsv";
import CopieContent from "../Exports/CopieContent";
import ExportPdf from "../Exports/ExportPdf";
import ImageComponent from "../Styles/ImageComponent";
import Pagination from "../Others/Pagination";
import moment from "moment";
import { error_message } from "../alerte/AlerteMessage";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation



function ListCheques() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [valides, setValides] = useState([]);
  const [encours, setEncours] = useState([]);
  const [refuses, setRefus] = useState([]);
  const [isCollapsed, setisCollapsed] = useState(false);
   const getLastMonthStartDate = () => {
      const date = new Date();
      date.setMonth(date.getMonth() - 1); // Go to last month
      date.setHours(12, 0, 0, 0); // Ensure date is accurate to today's date by setting noon
      return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };
    
    // Function to get today's date
    const getTodayDate = () => {
      const date = new Date();
      date.setHours(12, 0, 0, 0); // Ensure date is accurate to today's date by setting noon
      return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };
    
    const [startDate, setStartDate] = useState(getLastMonthStartDate);
    const [endDate, setEndDate] = useState(getTodayDate);
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  /**
   * It gets all the data from the API and sets the data in the state.
   */
  
  const getData = () => {
    setLoading(true);
    getChequesByCode(startDate,endDate)
   
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        // const getAllAcompte = getfilter(res.data, "type", "acompte");
        // setValides(getfilter(getAllAcompte, "valide", "1"));
        // setEncours(getfilter(res.data, "type", "demande"));
        // setRefus(getfilter(getAllAcompte, "valide", "0"));
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  
  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the word that is typed in the search bar.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      const dataSearch = filterTeletravailByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * It deletes an acompte from the database.
   * @param id - The id of the record to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteChequeById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const onChangeDate = () => {
 
    const filteredDate = currentData.filter((listed) => {
      const momentDate = moment(listed.date);
      if(startDate > endDate){
        error_message(t("Attention La date début doit être inférieur à la date fin !!"))
      }else{
      return (
        momentDate.isSameOrAfter(moment(startDate)) &&
        momentDate.isSameOrBefore(moment(endDate))
      )};
    });
    setcurrentData(filteredDate);
  };
  return (
    <>
    
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Chèques tiroirs")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addcheque" className="btn btn-primary mr-3">
                {t("Ajouter chèque")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Total chèques")}</span>
                    <h3 className="mb-0 mt-1 text-success">{getSomme(data, "montant")}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="las la-users" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                         
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>       
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List chèques")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_cheques"} columns={["type","numero","montant","banque","date","echeance","fournisseur_code"]}/>
                          <ExportPdf data={data} name={"List_cheque"} columns={["type","numero","montant","banque","date","echeance","fournisseur_code"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                     
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                  <thead>
  <tr>
  <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("User")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Type")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Numéro")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Montant")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Banque")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Date")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Date Échéance")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Fournisseur")}</th>
    <th className="border-bottom-0" style={{ textAlign: "center" }}>{t("Action")}</th>
  </tr>
</thead>
<tbody>
  {currentData.map((el) => (
    <tr key={el.id}>
      
      <td className="text-center">{el.user_name} {el.user_pname}</td>
      <td className="text-center">{el.type}</td>
      <td className="text-center">{el.numero}</td>
      <td className="text-center">{el.montant}</td>
      <td className="text-center">{el.nombanque}</td>
      <td className="text-center">{el.date}</td>
      <td className="text-center">{el.echeance}</td>
      <td className="text-center">{el.nom}</td>
      <td className="text-center">
      <td>
          {true && (
          <div className="btn-group">
          <button
          style={{ textAlign: "center", padding: 2, width: 40 }}
          type="button"
          className="btn btn-secondary dropdown-toggle dropdown-icon"
          data-toggle="dropdown"
          >
          </button>
          <div className="dropdown-menu" role="menu">
          <Link to={"/admin/editcheque/" + el.id} className="dropdown-item">
           {t("Editer")}
          
          </Link>
          <div className="dropdown-divider" />
          <Link to="#" onClick={() => handleDelete(el.id)} className="dropdown-item">
            {t("Supprimer")}
          </Link>
          </div>
          </div>
             )}
       </td>
      </td>
    </tr>
  ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ListCheques;