import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  deleteDechargeById,
  deleteEnteteByCode,
  editEnteteAfterDelete,
  getEnteteCommercialVente,
  getlistAnneeVente,
} from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit, soustractMontant, soustractSolde } from "../../../../../Utils/NumberUtils";
import { sumEncaissement, sumMontant, sumMontantHT, sumMontantTTC, sumMontantTVA, sumSolde } from "../../../../../Utils/ObjectOperation";
import { toast, ToastContainer } from "react-toastify";
import { compareDate, date_fr, getCompareTodayBetweenTwoDate } from "../../../../../Utils/DateUtils";
import moment from "moment";
import { deleteLigneByCodeEntete } from "../../../../../Services/Facturation/FacturationLigneApi";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";
import { getEncaissementByCode, getEncaissementByYear } from "../../../../../Services/Facturation/FacturationEncaissementApi";
import { checkMontanPaiement, groupByNumeroPiece, groupByNumPiece } from "../../../../../Utils/FactureUtils";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import "./ListFactureVente.css";
import ExportCsv from "../../../../Exports/ExportCsv";
import ExportPdf from "../../../../Exports/ExportPdf";
import CopieContent from "../../../../Exports/CopieContent";
import swal from "sweetalert";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { success_message, error_message } from "../../../../../Components/alerte/AlerteMessage";
import { useParams } from "react-router-dom";
import Cards from "../../../../cards/Cards";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import ExportCsvFactureVente from "../../../../Exports/ExportCsvFactureVente";

function StaticExample(props) {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t

  return (
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>{t("Modal title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t("Modal body text goes here")}.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary">{t("Close")}</Button>
        <Button variant="primary">{t("Save changes")}</Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

function ListFactureVente() {
  let history = useHistory();
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(40);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [allEncaiss, setallEncaiss] = useState(null);
  const [resultEnc, setresultEnc] = useState(null);

  // -------- DATE DEBUT / DATE FIN ----------- 
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  const [show, setShow] = useState(false);
  const [listAnnnees, setListAnnnes] = useState([]);
  const [selectedAnnee, setselectedAnnee] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData(selectedAnnee);
  };


  useEffect(() => {
    getlistAnneeVente()
      .then((res) => {
        setListAnnnes(res.data)

        setselectedAnnee(res.data[0].year)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (listAnnnees.length !== 0) {
      getData(listAnnnees[0].year);

    } else {

      getData(new Date().getFullYear());

    }
  }, [listAnnnees]);

  const getData = (d) => {
    setLoading(true);
    getEnteteCommercialVente(d)
      .then((res) => {
        const currentYearArray = res.data;

        getEncaissementByYear(d).then((resEncaissement) => {
          const goupedEncaissement = groupByNumeroPiece(resEncaissement.data);
          setallEncaiss(resEncaissement.data);
          // merge entete et encaissement
          const mergeById = (a1, a2) =>
            a1.map((itm) => ({
              ...a2.find((item) => item.numero_piece === itm.code && item),
              ...itm,
            }));

          const mergedList = mergeById(currentYearArray, goupedEncaissement);
            console.log('mergedListmergedListmergedList',resEncaissement)
          setdata(mergedList);
          setallInitialData(mergedList);
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterFactureByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };


  const handleSelectStaus = (e) => {
    switch (e.target.value) {
      case "1":
        setdata(allInitialData.filter((el) => !checkMontanPaiement(el?.montant_encaissement)));
        break;
      case "2":
        setdata(allInitialData.filter((el) => checkMontanPaiement(el?.montant_encaissement) == el.net_a_payer));
        break;

      case "3":
        setdata(
          allInitialData.filter(
            (el) => checkMontanPaiement(el?.montant_encaissement) < el.net_a_payer && checkMontanPaiement(el?.montant_encaissement) > 0
          )
        );
        break;
        {/**  case "4":
        setdata(allInitialData.filter((el) => checkMontanPaiement(el?.montant_encaissement) == 0 && compareDate(el.date_prevu_livraison)));
        break;*/}
      default:
        setdata(allInitialData);
    }
  };
  const handleDeletefacture = (code, factureCode) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        getEnteteCommercialVente(selectedAnnee).then((result) => {
          
          var lastCodeFacture = result.data[0].facture_code.split("_")[1];
          var lastListFacture = factureCode.split("_")[1];

          if (parseInt(lastCodeFacture) === parseInt(lastListFacture)) {
            deleteLigneByCodeEntete(code).then((res) => {
              deleteEnteteByCode(code).then((res) => {
                getData(new Date().getFullYear());
              });
            });
          } else {
            deleteLigneByCodeEntete(code).then((res) => {
              editEnteteAfterDelete(code).then((res) => {
                swal(t("Opération effectuée avec succès!"), {
                  icon: "success",
                });
                getData(new Date().getFullYear());
              });
            });
          }
        });
      }
    });
  };

  const handleGoEncaissement = (el) => {
    localStorage.setItem("facture", JSON.stringify(el));
    history.push("/admin/addEncaissement/" + el.id, el);
  };


  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const hadleShowEncaissement = (value) => {
    console.log(value);
    console.log(allEncaiss);
    const resultEncaissement = allEncaiss.filter((el) => el.num_facture == value);
    console.log(resultEncaissement);
    setresultEnc(allEncaiss.filter((el) => el.num_facture == value));
    setShow(true);
  };

  const handleRemoveDecharge = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment supprimé décharge!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal(t("Opération effectuée avec succès!"), {
          icon: "success",
        });

        deleteDechargeById(id).then((res) => {
          getData(new Date().getFullYear());
        });
      }
    });
  };


  // In your parent component
  const updateCurrentData = (newData) => {
    setcurrentData(newData);
  };
  const onChangeDate = () => {
    const filteredData = filterByDate(allInitialData, startDate, endDate);
    updateCurrentData(filteredData);
  };

  const filterByDate = (allInitialData, startDate, endDate) => {
    if (startDate === '' || endDate === '') {
      // Si l'une des dates est vide, ne filtre pas et affiche toutes les données.
      return allInitialData;
    } else {
      const filteredDate = allInitialData.filter((listed) => {
        const momentDate = moment(listed.date.split(" ")[0]);
        if (moment(startDate).isAfter(endDate)) {
          error_message(t("Attention, la date de début doit être antérieure à la date de fin !"));
        } else {
          return (
            momentDate.isSameOrAfter(moment(startDate)) &&
            momentDate.isSameOrBefore(moment(endDate))
          );
        }
      });
      return filteredDate;
    }
  };

  const handleSelectDate = (e) => {
    const val = e.target.value;
    setselectedAnnee(val)
    getData(val);
  };
  return (
    <>
      {resultEnc && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Encaissement")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {/* <ClientEncaissement encaissement={encaissements}></ClientEncaissement> */}
              <div className="table-responsive">
                {/* <Link to="#" className="btn btn-primary btn-tableview">
                        Add Invoice
                      </Link> */}
                <table className="table  table-vcenter text-nowrap table-bordered border-bottom border-top" id="invoice-tables">
                  <thead>
                    <tr>
                      <th>{t("N° Facture")}</th>
                      <th>{t("Ref Facture")}</th>
                      <th>
                        {t("Montant HT")} <br></br>
                        {/* <span className="badge badge-pill badge-danger">{sumMontant(resultEnc)}</span> */}
                      </th>
                      <th>{t("Date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultEnc?.map((el, idx) => (
                      <tr key={idx}>
                        <td>
                          <Link to={"/admin/facturation/editencaissement/" + el?.id}>{el.num_facture}</Link>
                        </td>
                        <td>
                          {el.type === "facture" && <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.code_facture}</Link>}
                          {el.type === "devis" && <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.code_facture}</Link>}
                          {el.type === "bl" && <Link to={"/admin/editerblvente/" + el.code_facture}>{el.code_facture}</Link>}
                          {el.type === "commande" && <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.code_facture}</Link>}
                        </td>
                        <td>{convertToDigit(el.montant)}</td>
                        <td>{el.created_at}</td>
                      </tr>

                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Fermer")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div className="page-header d-xl-flex d-block">
      
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
            <select className="btn btn-primary mr-3 " onChange={handleSelectDate} >
            {listAnnnees.map((item, index) => (
              <option key={index} value={item.year}>Facture de vente {item.year}</option>

            ))}

          </select>
              <Link to="/admin/ajoutfacturevente" className="btn btn-primary mr-3">
              <i className="fa fa-plus" />
              </Link>
             
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flexrow">
        {/* <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header " style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant HT")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-success">{sumMontantHT(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TVA")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TTC")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Encaissement")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-danger">{sumEncaissement(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Solde")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-danger">{sumSolde(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
      </div>



      <div className="row">

        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Liste des factures ")}</h4>
              <div className="col-xl-8 col-md-12">

              </div>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">


              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 ml-9">
                        <div id="hr-table_filter" className="dataTables_filter">
                        <ExportCsvFactureVente data={data} name={"List_factures_ventes"} />
                          <ExportPdf
                            data={data}
                            name={"List_factures_ventes"}
                            columns={[
                              "facture_code",
                              "client_nom",
                              "montant_total_ht",
                              "montant_total_tva",
                              "montant_total_ttc",
                              "montant_encaissement",
                              "net_a_payer",
                            ]}
                          />
                          <CopieContent />


                          <label className="mr-2">
                           
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
   


                        


                            {/* <input
                              className="form-control floating"
                              type="date"
                              onChange={(e) => filterByDate(e.target.value)}
                            /> */}
                          </label>

                          
                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2 mr-3">
                        <div id="hr-table_info" role="status" aria-live="polite">
                        <select
                             
                              className="form-control"
                              placeholder={t("choisir statut...")}
                             
                              onChange={handleSelectStaus}
                         >
                              <option>choisir statut...</option>
                              <option value={0}>{t("Tout")}</option>
                              <option value={1}>{t("En cours")}</option>
                              <option value={2}>{t("Payée")}</option>
                              <option value={3}>{t("Partiel")}</option>
                              <option value={4}>{t("échu")}</option>
                            </select>                    </div>
                      </div>

                     
                   
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0">{t("N° Facture")}</th>
                              <th className="border-bottom-0">{t("Client")}</th>
                              <th className="border-bottom-0">{t("Date")}</th>
                              <th className="border-bottom-0">{t("Montant HT")}</th>
                              <th className="border-bottom-0">{t("Montant TVA")}</th>
                              <th className="border-bottom-0">{t(" Net a payer")}</th>
                              <th className="border-bottom-0">{t("Encaissement")}</th>
                              <th className="border-bottom-0">{t("Solde")}</th>
                              <th className="border-bottom-0" style={{ width: 60 }}>
                                {t("Statut")}
                              </th>
                              <th className="border-bottom-0">{t("Date d'échéance")}</th>
                              <th className="border-bottom-0"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={el.id}>
                                <td>
                                  <div className="d-flex">
                                    <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                                    <div className="mt-1 mr-4">
                                      <Link to={"/admin/editerfacturevente/" + el.code}>{el.facture_code}</Link>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <Link to={"/admin/clientactivity/" + el.client_code}>{el.client_nom}</Link>
                                </td>

                                <td>{el.date.split(" ")[0] === "2030-01-01" ? "" : date_fr(el.date.split(" ")[0])}</td>

                                <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.net_a_payer_ht)}</td>
                                <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.montant_total_tva)}</td>
                                <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.net_a_payer)}</td>

                                <td
                                  style={{ textAlign: "right" }}
                                  onClick={() => hadleShowEncaissement(el.facture_code)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={"Clicker pour voir l'encaissement"}
                                >
                                  {convertToDigit(checkMontanPaiement(el?.montant_encaissement))}
                                </td>

                                {/* <td>dd</td> */}
                                <td style={{ textAlign: "right" }}>
                                  {el.montant_total_ttc == "0"
                                    ? ""
                                    : soustractSolde(el.net_a_payer, checkMontanPaiement(el?.montant_encaissement))}
                                  {/* soustractSolde(el.montant_total_ttc, checkMontanPaiement(el?.montant_encaissement)) */}
                                </td>

                                <td>
                                  {checkMontanPaiement(el?.montant_encaissement) === el.net_a_payer && el.net_a_payer !== 0 && (
                                    <span className="badge badge-success">{t("payée")}</span>
                                  )}
                                  {checkMontanPaiement(el?.montant_encaissement) > el.net_a_payer && (
                                    <span className="badge badge-success">{t("payée")}</span>
                                  )}

                                  {checkMontanPaiement(el?.montant_encaissement) < el.net_a_payer &&
                                    checkMontanPaiement(el?.montant_encaissement) > 0 && (
                                      <>
                                        <span className="badge badge-warning">{t("partiel")}</span>
                                        {compareDate(el.date_echeance) && <span className="badge badge-danger ml-1">{t("échu")}</span>}
                                      </>
                                    )}

                                  {checkMontanPaiement(el?.montant_encaissement) === 0 && (
                                    <>{compareDate(el.date_echeance) && <span className="badge badge-danger ml-1">{t("échu")}</span>}</>
                                  )}
                                  {checkMontanPaiement(el?.montant_encaissement) === 0 && el.montant_total_ttc === 0 && (
                                    <span className="badge badge-primary ml-1">{t("vide")}</span>
                                  )}
                                </td>

                                <td>{el.date_echeance !== null ? date_fr(el.date_echeance?.split("T")[0]) : ''}</td>
                                <td>
                                  {true && (
                                    <div className="btn-group">
                                      <button
                                        style={{ textAlign: "center", padding: 1, width: 30 }}
                                        type="button"
                                        className="btn btn-secondary dropdown-toggle dropdown-icon"
                                        data-toggle="dropdown"
                                      ></button>
                                      <div className="dropdown-menu" role="menu">
                                        <Link to="#" onClick={() => handleGoEncaissement(el)} className="dropdown-item">
                                          {t("Ajouter encaissement")}
                                        </Link>

                                        {/* <div className="dropdown-divider" /> */}
                                        <Link to={"/admin/editerfacturevente/" + el.code} className="dropdown-item">
                                          {t("Editer document")}
                                        </Link>
                                        <Link to={"/admin/editeravoirvente/" + el.code} className="dropdown-item">
                                          {t("Editer Avoir")}
                                        </Link>
                                        {el.image ? (
                                          <>
                                            <Link to={"/admin/facturation/uploaddecharge/" + el.code} className="dropdown-item">
                                              {t("Voir décharge")}
                                            </Link>
                                            <Link to="#" className="dropdown-item" onClick={() => handleRemoveDecharge(el.id)}>
                                              {t("Supprimer décharge")}
                                            </Link>
                                          </>
                                        ) : (
                                          <Link to={"/admin/facturation/uploaddecharge/" + el.code} className="dropdown-item">
                                            {t("Ajouter décharge")}
                                          </Link>
                                        )}

                                        <div className="dropdown-divider" />
                                        {checkMontanPaiement(el?.montant_encaissement) == 0 && (
                                          <Link to="#" onClick={() => handleDeletefacture(el.code, el.facture_code)} className="dropdown-item">
                                            {t("Supprimer facture")}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th className="border-bottom-0">{t("N° Facture")}</th>
                              <th className="border-bottom-0">{t("Client")}</th>
                              <th className="border-bottom-0">{t("Date")}</th>
                              <th className="border-bottom-0">{t("Montant HT")}</th>
                              <th className="border-bottom-0">{t("Montant TVA")}</th>
                              <th className="border-bottom-0">{t("Montant TTC")}</th>
                              <th className="border-bottom-0">{t("Encaissement")}</th>
                              <th className="border-bottom-0">{t("Solde")}</th>
                              <th className="border-bottom-0" style={{ width: 60 }}>
                                {t("Statut")}
                              </th>
                              <th className="border-bottom-0">{t("Date d'échéance")}</th>
                              <th className="border-bottom-0"></th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListFactureVente;
