import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ajouterCheque, ajouterEntetCheque } from "../../Services/Pointeuse/ChequeApi";
import { useAlert } from "react-alert";
import { use } from "react";
import { getbanques } from "../../Services/banque";

function CarnetCheque() {
  const history = useHistory();
  const alert = useAlert();
  
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [num1, setNum1] = useState("");
  const [nombre, setNombre] = useState("");
  const [numFinal, setNumFinal] = useState("");
  const [banque, setBanque] = useState("");
  const [banquelist, setBanquelist] = useState([]);

  // Calcul automatique de numFinal
  useEffect(() => {
    if (num1 && nombre) {
      setNumFinal((parseInt(num1, 10) + parseInt(nombre, 10)) - 1);
    }
  }, [num1, nombre]);

  useEffect(()=>{
    getbanques().then(res=>{
      console.log('res',res)
      setBanquelist(res.data)
      

    });
  },[])
  const handleSubmit = (e) => {
    e.preventDefault();

    // Vérification que tous les champs sont remplis
    if (!num1 || !nombre || !banque) {
      alert.error("Veuillez remplir tous les champs !");
      return;
    }else{
      ajouterEntetCheque(date, num1, banque, nombre, numFinal).then(() => {
      alert.success("Carnet de chèque ajouté avec succès !");
 
      history.goBack(); // Revenir à la page précédente
     // history.push("/admin/cheque");
    }).catch(() => {
    //  alert.error("Erreur lors de l'ajout !");
    });
    };

    // Envoi des données à l'API
    
  };

  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">Ajouter Carnet Chèque</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
            <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
          </div> */}
              <div class="col-sm-12 col-lg-12">
                <form style={{ marginTop: 50 }} onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Date</label>
                    <input type="date" className="form-control" value={date} readOnly />
                  </div>
                  <div className="form-group">
                    <label>Num 1</label>
                    <input type="number" className="form-control" value={num1} onChange={(e) => setNum1(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>Nombre</label>
                    <input type="number" className="form-control" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                  </div>   
                  <div className="form-group">
          
                                <label>Banque </label>
                                {banquelist&& (
                                  <select className="form-control"  value={banque} onChange={(e) => setBanque(e.target.value)}>
                                    <option value=""> Choisir banque </option>
                                    {banquelist.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.banque}
                                      </option>
                                     ))}
                                  </select>
                                )}
                  </div> 
                  <div className="form-group">
                    <label>Num Final</label>
                    <input type="number" className="form-control" value={numFinal} readOnly />
                  </div>
                  <button type="submit" className="btn btn-primary mt-2">Ajouter</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarnetCheque;
