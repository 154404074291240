import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getChequeById } from "../../Services/Pointeuse/ChequeApi";
import Select from "react-select";
import { useAlert } from "react-alert";
import { getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import { getbanques } from "../../Services/banque";
import { t } from "i18next";
import { addChequeEmisLigne, findIdLigne,findNumeroCheque,updateChequeById} from "../../Services/Pointeuse/ChequeEmisLigne";
import { toast } from "react-toastify";

function AddCheque(props) {
  const history = useHistory();
  const alert = useAlert();

  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [type, setType] = useState("cheque");
  const [numero, setNumero] = useState("");
  const [montant, setMontant] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [dateEcheance, setDateEcheance] = useState(new Date().toISOString().split("T")[0]);
  const [banqueCode, setbanqueCode] = useState(null);
  const [databanque, setDatabanque] = useState([]);
  const [entetid, setentetid] = useState(null);

  const handleChangebanque = (event) => {
    setbanqueCode(event.target.value);
  };
  useEffect(() => {
    if (props.match.params?.id) {
      findIdLigne(props.match.params.id)
        .then((res) => {
          setMontant(res.data.montant);
          setDate(res.data.date);
          setDateEcheance(res.data.dateEcheance);
          setFournisseur(res.data.fournisseur_code);
          setNumero(res.data.numero);
          setType(res.data.type);
          setbanqueCode(res.data.banque);
        })
        .catch(() => toast.error("Erreur lors du chargement du chèque."));
    }
  }, [props.match.params?.id]);

  useEffect(()=>{
    if(type==="cheque"){
      findNumeroCheque().then((res)=>{
        if(res&&res.status===200){
          if(!res.data.find){
            toast.warning(res.data.message)
          }else{
            setNumero(res.data.data.numero)
            setentetid(res.data.data.entet_id)
          }
        }
      })
    }else{
      setentetid(null)
      setNumero('')
    }
  },[type])

  
  useEffect(() => {
    getFacturationClientByCode()
      .then((res) => {
        const options = res.data.map((d) => ({
          value: d.code,
          label: `${d.nom} ${d.prenom}`,
        }));
        setDataFournisseur(options);
      })
      .catch(() => alert.error("Erreur lors du chargement des fournisseurs."));

      getbanques() .then((res)=>{setDatabanque(res.data);})
      .catch(() => alert.error("Erreur lors du chargement des banques."));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!fournisseur || !banqueCode) {
      alert.error("Veuillez sélectionner un fournisseur et une banque.");
      return;
    }
  
    if (props.match.params?.id) {
      // Si un ID est présent, on met à jour
      updateChequeById(date, dateEcheance, fournisseur, montant, numero, type, banqueCode, props.match.params.id)
        .then(() => {
          toast.success("Modifié avec succès");
          history.push("/admin/cheque");
        })
        .catch(() => {
          alert.error("Erreur lors de la mise à jour du chèque.");
        });
    } else {
      // Sinon, on ajoute un nouveau chèque
      addChequeEmisLigne(date, dateEcheance, fournisseur, montant, numero, type, banqueCode,entetid)
        .then(() => {
          alert.success("Chèque ajouté avec succès !");
          history.push("/admin/cheque");
        })
        .catch(() => {
          alert.error("Erreur lors de l'ajout du chèque.");
        });
    }
  };
  
  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header border-0">
          <h4 className="card-title">{t("Ajouter Chèque / Traite")}</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
<div>
            <div className="form-group">
              <label>{t("Type de paiement")}</label>
              <div className="d-flex align-items-center">
                <div className="form-check me-3">
                  <input
                    type="radio"
                    id="traite"
                    value="traite"
                    checked={type === "traite"}
                    onChange={() => setType("traite")}
                    className="form-check-input"
                  />
                  <label htmlFor="traite" className="form-check-label ms-1">
                    {t("Traite")}
                  </label></div>
<div>
                </div>
                <div className="col-xl-1 col-md-1 col-lg-1 mt-4"></div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="cheque"
                    value="cheque"
                    checked={type === "cheque"}
                    onChange={() => setType("cheque")}
                    className="form-check-input"
                  />
                  <label htmlFor="cheque" className="form-check-label ms-1">
                    {t("Chèque")}
                  </label>
                </div>
              </div>
            </div>
      </div>
            <div className="form-group">
              <label>{t("Numéro")}</label>
              <input
                type="text"
                className="form-control"
                required
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>{t("Montant")}</label>
              <input
                type="number"
                className="form-control"
                required
                value={montant}
                onChange={(e) => setMontant(e.target.value)}
              />
            </div>
             <div className="form-group">
                      <label>Banque </label>
                      {databanque && (
                        <select className="form-control"  value={banqueCode}onChange={handleChangebanque}>
                          <option value=""> Choisir banque </option>
                          {databanque.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.banque}
                            </option>
                           ))}
                        </select>
                      )}
                    </div>

            <div className="form-group">
              <label>{t("Date")}</label>
              <input
                type="date"
                className="form-control"
                required
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>{t("Date d'échéance")}</label>
              <input
                type="date"
                className="form-control"
                required
                value={dateEcheance}
                onChange={(e) => setDateEcheance(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>{t("Fournisseur")}</label>
              <Select
              value={dataFournisseur.find(item=>item.value===fournisseur)}
                options={dataFournisseur}
                onChange={(option) => setFournisseur(option.value)}
                placeholder="Sélectionner un fournisseur"
              />
            </div>
            <button type="submit" className="btn btn-primary">{props.match.params?.id ? t("Modifier") : t("Ajouter")}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCheque;