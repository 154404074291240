import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";
const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const api_url = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get annonces by code
 */
export async function getChequesByCode(datedebut,datefin) {
  try {
    const response = await axios.get(api_url+ `cheques/users/${getSession("code_generated")}/${datedebut}/${datefin}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It deletes an cheques by id.
 * @param id - The id of the cheques to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteChequeById(id) {
  try {
    const response = await axios.delete(api_url + "cheques/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getChequeById(id) {
  try {
    const response = await axios.get(api_url + "cheques/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


export async function addChequeEmisLigne(date, dateEcheance,fournisseur, montant, numero, type,banque) {
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    date: date,
    fournisseur_code: fournisseur,
    echeance:dateEcheance,
    montant:montant,
    numero:numero,
    type:type,
    banque:banque,
  };

  try {
    const response = await axios.post(api_url_v2 + "cheque-emis-ligne", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
//date, num1, banque, nombre, numFinal
export async function ajouterEntetCheque(date, num1, banque, nombre,  numFinal) {
  //
const obj ={
  date :date,
  code_generated:getSession('code_generated'),
  premier_cheque:num1,
  nombre:nombre,
  banque:banque,
  dernier_cheque:numFinal,
  user_code:getSession('id'),
};



  try {
    const response = await axios.post(api_url + "cheque-emis-entete", obj, config);
    toast.success(t("Carnet Chéque ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}


export async function getEntetChequesByCode(startDate,endDate) {
  try {
    const response = await axios.get(api_url + `cheque-emis-entete/code/${getSession("code_generated")}/${startDate}/${endDate}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It deletes an cheques by id.
 * @param id - The id of the cheques to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteEntetChequeById(id) {
  try {
    const response = await axios.delete(api_url + "cheque-emis-entete/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getEntetChequeById(id) {
  try {
    const response = await axios.get(api_url + "cheque-emis-entete/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


export async function updateEntetChequeById( data) {
  const obj ={
    id:data.id,
    date :data.date,
    code_generated:getSession('code_generated'),
    premier_cheque:data.num1,
    nombre:data.nombre,
    banque:data.banque,
    dernier_cheque:data.numFinal,
    user_code:getSession('id'),
  };


  try {
    const response = await axios.patch(api_url + "cheque-emis-entete", obj,config);
    toast.success(t("Carnet Chéque modifié"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}




// Mettre à jour un chèque 
export async function updateChequeById(id, date, dateEcheance, fournisseur, montant, numero, type, banque) {
  const credentiel = {
    id: id,
    date: date,
    echeance: dateEcheance,
    fournisseur_code: fournisseur,
    montant: montant,
    numero: numero,
    type: type,
    banque: banque,
    code_generated: getSession("code_generated"),
  };

  try {
    const response = await axios.patch(api_url+ "cheque-emis-ligne", credentiel, config);
    toast.success(t("Chèque modifié"));
    return response;
  } catch (error) {
    if (error.response) {
      toast.error(t("Erreur serveur"));
      console.error("Erreur serveur :", error.response.data);
    } else if (error.request) {
      toast.error(t("Erreur requête"));
      console.error("Erreur requête :", error.request);
    } else {
      console.error(t("Erreur"), error.message);
    }
    return error;
  }
}
