import moment from "moment";
var _ = require("lodash");

/**
 * Given an array of objects, return the sum of the values of a given key
 * @param arrayObject - The array of objects that you want to sum.
 * @param value - The value to sum.
 * @returns The sum of the values in the array.
 */
export const sumObjectif = (arrayObject, value) => {
  let sum = arrayObject
    .map((o) => o[value]||0)
    .reduce((a, c) => {
      return Number(a) + Number(c);
    }, 0);
  return Number(sum);
};

/**
 * It sums the values of the objectif property of each object in the array.
 * @param arrayObject - The array of objects that you want to sum.
 * @param value - The value to be added to the sum.
 * @returns The sum of all the objectifs in the array.
 */
export const sumO = (arrayObject, value) => {
  let sum = arrayObject
    .map((o) => o?.objectif)
    .reduce((a, c) => {
      return parseInt(a) + parseInt(c);
    }, 0);
  return sum;
};

/**
 * Calculate the growth rate of a company based on the difference between the company's objective and
 * the company's realisation
 * @param objectif - the target value for the metric
 * @param realisation - The number of realisations for the current month.
 * @returns The percentage of the difference between the objectif and the realisation.
 */
export const calculateCroissance = (objectif, realisation) => {
  var result = (Number(objectif) - Number(realisation)) / Number(realisation);
  return (result * 100).toFixed(0);
};

 // Function to calculate growth rate based on the formula provided
 export const calculateCroissanceCA = (currentMonthRealisation, prevYearSameMonthRealisation) => {
  if (!prevYearSameMonthRealisation || prevYearSameMonthRealisation === 0) {
    return 0; // Avoid division by zero
  }

  const growthRate = ((currentMonthRealisation - prevYearSameMonthRealisation) / prevYearSameMonthRealisation) * 100;
  return growthRate.toFixed(0);
};

/**
 * Calculate the total growth rate of a company based on the difference between the objectif and the
 * realisation
 * @param objectif - the objectif value for the current month
 * @param realisation - The number of realisations for the current month.
 * @returns The percentage of growth.
 */
export const calculateTotalCroissance = (objectif, realisation) => {
  var result = (Number(objectif) - Number(realisation)) / Number(realisation);
  return (result * 100).toFixed(0);
};

/**
 * Sort an array of objects by month and year
 * @param arr - the array to sort
 * @returns An array of objects.
 */
export const sortByMonth = (arr) => {
  var months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "décembre"];
  const sorter = (a, b) => {
    if (a.annee !== b.annee) {
      return a.annee - b.annee;
    } else {
      return months.indexOf(a.mois) - months.indexOf(b.mois);
    }
  };
  return arr.sort(sorter);
};

/**
 * It groups the data by month.
 * @param arr - The array of objects to be grouped.
 * @param currentYear - the current year
 * @returns an array of objects with the following structure:
 */
export const groupeByMonth = (arr, currentYear) => {
   const objectYear = arr.filter((el) => {
    return el.date.split("-")[0] === currentYear;
  });
  let grouped_items = _.groupBy(objectYear, (b) => moment(b.date).startOf("month").format("YYYY-MM"));
  var finalResult = Object.entries(grouped_items).map(function (group) {
    return {
      date: group[0],
      montant_total_ht: group[1]
        .map((o) => o?.montant_total_ht)
        .reduce((a, c) => {
          return Number(a) + Number(c);
        }, 0),
    };
  });
  finalResult.sort(function (a, b) {
    return a.date.split("-")[1] - b.date.split("-")[1];
  });
  var newObj = [
    {
      date: "2021-01",
      montant_total_ht: 0,
    },
    {
      date: "2021-02",
      montant_total_ht: 0,
    },
    {
      date: "2021-03",
      montant_total_ht: 0,
    },
    {
      date: "2021-04",
      montant_total_ht: 0,
    },
    {
      date: "2021-05",
      montant_total_ht: 0,
    },
    {
      date: "2021-06",
      montant_total_ht: 0,
    },
    {
      date: "2021-07",
      montant_total_ht: 0,
    },
    {
      date: "2021-08",
      montant_total_ht: 0,
    },
    {
      date: "2021-09",
      montant_total_ht: 0,
    },
    {
      date: "2021-10",
      montant_total_ht: 0,
    },
    {
      date: "2021-11",
      montant_total_ht: 0,
    },
    {
      date: "2021-12",
      montant_total_ht: 0,
    },
  ];
  var hash = Object.create(null);
  finalResult.forEach(function (a) {
    hash[a.date] = true;
  });
  newObj.forEach(function (a) {
    hash[a.date] || finalResult.push(a);
  });

  return finalResult;
};

/**
 * Calculate the percentage of the difference between the objective and the realisation
 * @param objectif - the target value for the metric
 * @param realisation - The realisation value for the current month.
 * @returns The percentage of the difference between the objectif and the realisation.
 */
export const calculateRealisationCroissance = (objectif, realisation) => {
  if (realisation == 0) {
    return 0;
  }
  var result = (Number(objectif) - Number(realisation)) / Number(realisation);
  return (result * 100).toFixed(0);
};

/**
 * Calculate the percentage of realisation of the objectif
 * @param realisation - The number of realisations
 * @param objectif - The target value for the metric.
 * @returns The percentage of the realisation of the objectif.
 */
export const calculateRealisationVSObjectif = (realisation, objectif) => {
  if (realisation === 0) {
    return 0;
  }
  var result = Number(realisation) / Number(objectif);
  return (result * 100).toFixed(0);
};

export const sumObjectValue = (arrayObject, value, equal, tosum) => {
  const filtered = arrayObject.filter((o) => {
    return o[value] == equal;
  });
  let sum = filtered
    .map((o) => o[tosum])
    .reduce((a, c) => {
      return Number(a) + Number(c);
    }, 0);
  return Number(sum);
};

export const sumObjectCurrentMonth = (arrayObject) => {
  const currentMonth = new Date().getMonth() + 1;
  const filteredDebit = arrayObject.filter((o) => {
    return o.operation == "debit" && o.date.split("-")[1] == currentMonth;
  });
  const filteredCredit = arrayObject.filter((o) => {
    return o.operation == "credit" && o.date.split("-")[1] == currentMonth;
  });

  return Number(sumObjectif(filteredDebit, "montant_entrant")) - Number(sumObjectif(filteredCredit, "montant_sortant"));
};
