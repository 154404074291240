import React, { useEffect, useState } from 'react'


import { getExercice } from '../Services/Pointeuse/EntrepriseApi'
import { useTranslation } from 'react-i18next'
import FacturePartiellementPayee from '../Components/ComptableDashboard/FacturePartiellementPayee'
import FacturePayer from '../Components/ComptableDashboard/FacturePayer'
import FactureNonPayer from '../Components/ComptableDashboard/FactureNonPayer'
import Objectif from '../Components/Objectif/Objectif'
import CardClient from '../Components/DashboardComptable/CardClient'
export default function ComptableDashboard() {
  const [ListExercice, setListeExercice] = useState([])
  const [selectExercice, setselectedExercice] = useState()
  const [selectExerciceClient, setselectedExerciceClient] = useState()

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getExercice().then((res) => {
      if (Array.isArray(res?.data)) {
        setListeExercice(res.data);
        setselectedExerciceClient(res.data[0].code_exercice)
        setselectedExercice(res.data[0].code_exercice)

      }
    })
  }, [])
  return (
    <div>
      <div className="card  p-6">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Facture Vente")}</h4>
        </div>
        <div className='row '>
          <div className='col-4 '  >
            <h5 className="mb-0 mt-3  ">{t("Exercice")}</h5>

            <select className="form-control" value={selectExercice} onChange={(e) => setselectedExercice(e.target.value)}>
              {ListExercice && ListExercice?.map((item, index) => (
                <option key={index} value={item.code_exercice}>
                  {item.code_exercice}
                </option>
              ))}
            </select>
          </div>

        </div>


        <div className='row mt-5'>

          <div className='col-4'>
            <FacturePartiellementPayee exercice={selectExercice} />
          </div>
          <div className='col-4'>
            <FacturePayer exercice={selectExercice} />
          </div>
          <div className='col-4'>
            <FactureNonPayer exercice={selectExercice} />
          </div>
        </div>
      </div>
      <div className="card  p-6">
      <div className='row '>
          <div className='col-4 '  >
            <h5 className="mb-0 mt-3  ">{t("Exercice")}</h5>

            <select className="form-control" value={selectExerciceClient} onChange={(e) => setselectedExerciceClient(e.target.value)}>
              {ListExercice && ListExercice?.map((item, index) => (
                <option key={index} value={item.code_exercice}>
                  {item.code_exercice}
                </option>
              ))}
            </select>
          </div>

        </div>
        <CardClient  exercice={selectExerciceClient}/>
      </div>
      <div className="card  p-6">
        <Objectif />
      </div>
      
    </div>

  )
}