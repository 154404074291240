import React, { useEffect, useState } from 'react'

import CardFacturePartiellementPayee from '../Components/DashboardComptable/CardFacturePartiellementPayee'
import CardFacturePayer from '../Components/DashboardComptable/CardFacturePayer'
import CardFactureNonPayer from '../Components/DashboardComptable/CardFactureNonPayer'
import { getExercice } from '../Services/Pointeuse/EntrepriseApi'
import CardClient from '../Components/DashboardComptable/CardClient'
export default function DashboardComptable() {

  const [ListExercice, setListeExercice] = useState([])
  const [selectExercice, setselectedExercice] = useState()

  useEffect(() => {
    getExercice().then((res) => {
      if (Array.isArray(res?.data)) {
        setListeExercice(res.data);
        setselectedExercice(res.data[0].code_exercice)

      }
    })
  }, [])


  return (
    <div className='mb-5'>
    <div className='row '>
      <div className='col-6'>

        <CardFacturePartiellementPayee />
      </div>
      <div className='col-6'>
        <CardFacturePayer />
        <CardFactureNonPayer />

      </div>
    </div>
    <div >

      <CardClient  exercice={selectExercice}/>
    </div>
      </div>
  )
}