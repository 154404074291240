import axios from "axios";
import { type } from "os";
import { getCurrentDateTime } from "../../Utils/DateUtils";
import { getToken, getSession } from "../../Utils/SessionUtils";
import moment from "moment";
import { t } from "i18next";
const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};
export async function getCaisseByCode(datedebut,datefin) {
  try {
    const response = await axios.get(api_url_v2 + `caisses/code/${getSession("code_generated")}/${datedebut}/${datefin}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getCaisseById(id) {
  try {
    const response = await axios.get(api_url_v2 + "caisses/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function addCaisse(date, client_code, description, cente_cout, operation, caisse, debit, credit, type,banque,solde) {
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    date: date,
    client_code: client_code,
    description: description,
    cente_cout: cente_cout,
    operation: operation,
    caisse: caisse,
    montant_entrant:operation==="debit"?0: solde,
    montant_sortant:operation==="credit"?0:solde,
    type: type,
    banque:banque,
    user_code:getSession(t("id"))
  };
  try {
    const response = await axios.post(api_url_v2 + "caisses", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function updateCaisse(date, client_code, description, cente_cout, operation, debit, credit, type,banqueCode,solde, id) {
  const credentiel = {
    date: date,
    client_code: client_code,
    description: description,
    cente_cout: cente_cout,
    operation: operation,
    montant_entrant: operation==="credit" ? solde:0,
    montant_sortant: operation==="debit" ? solde:0,
    banque:banqueCode,
    type: type,
    id: id,
    user_code:getSession(t("id")),
  };

  try {
    const response = await axios.patch(api_url_v2 + "caisses", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
export async function deleteCaisseById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "caisses/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
