import React, { useEffect, useState } from "react";
import { deleteChequeById, deleteEntetChequeById, getChequesByCode, getEntetChequesByCode } from "../../Services/Pointeuse/ChequeApi";
import { filterTeletravailByWord } from "../../Utils/SortObject";
import swal from "sweetalert";
import { t } from "i18next";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import { getSomme } from "../../Utils/ObjectFilter";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import moment, { months } from "moment";
import CopieContent from "../Exports/CopieContent";
import { error_message } from "../alerte/AlerteMessage";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import ViewCarnetModal from "./ViewCarnetModal";
import { date_fr } from "../../Utils/DateUtils";
function ListCarnetCheque() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [valides, setValides] = useState([]);
  const [encours, setEncours] = useState([]);
  const [refuses, setRefus] = useState([]);
  const [idCarnet, setidCarnet]=useState(null);
  const [showModal ,setshowModal]= useState(false)
  const [isCollapsed, setisCollapsed] = useState(false);
  const [carnet, setCarnet]=useState("");

  /**
   * It gets all the data from the API and sets the data in the state.
   */
  const getLastMonthStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); // Go to last month
    date.setDate(1); // Set to the first day of that month
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // Function to get tomorrow's date
  const getTomorrowDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1); // Set to tomorrow
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // Initialize state with calculated dates
  const [startDate, setStartDate] = useState(getLastMonthStartDate());
  const [endDate, setEndDate] = useState(getTomorrowDate());

  const getData = () => {
    setLoading(true);
    getEntetChequesByCode(startDate,endDate)
      .then((res) => {
        console.log('res',res)
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleupdate=(itemid)=>{
    setidCarnet(itemid)
    setshowModal(true)
  };
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const filterByDate = (value) => {
      const filterd = allInitialData.filter((el) => {
        if (el.date) {
          return el.date.split(" ")[0] == value;
        }
      });
  
      if (filterd.length === 0) {
        setdata(allInitialData);
      } else {
        setdata(filterd);
      }
    };
  
  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the word that is typed in the search bar.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      const dataSearch = filterTeletravailByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * It deletes an acompte from the database.
   * @param id - The id of the record to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEntetChequeById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };



  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
/* filtrage du dtae par date-debut et date-fin  */
  const onChangeDate = () => {
    getData();
  };
  

  return (
    <>
    
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Carnets des Chèques ")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addcheque" className="btn btn-primary mr-3">
                {t("Ajouter chèque")}
              </Link>
              <Link to="/admin/addcarnetcheque" className="btn btn-primary mr-3">
                {t("Ajouter carnet chèque")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

    
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                         
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>       
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List chèques")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_cheques_emis_entete"} />
                          <ExportPdf data={data} name={"List_cheques_emis_entete"} columns={["date", "premier_cheque", "nombre", "banque", "dernier_cheque"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                     
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                               <th className="border-bottom-0 text-center">{t("Date")}</th>
                               <th className="border-bottom-0 text-center">{t("Num1")}</th>
                               <th className="border-bottom-0 text-center">{t("Nombre")}</th>
                               <th className="border-bottom-0 text-center">{t("Banque")}</th> 
                               <th className="border-bottom-0 text-center">{t("Num final")}</th>
                               <th className="border-bottom-0 text-center">{t("Nom")}</th>
                               <th className="border-bottom-0 text-center">{t("Action")}</th>
                               </tr>
                            </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td className="text-center">{date_fr(el.date)}</td>
                                <td className="text-center">{el.premier_cheque}</td>
                                <td className="text-center">{el.nombre}</td>
                                <td className="text-center">{el.bnoms}</td>
                                <td className="text-center">{(el.premier_cheque+el.nombre)-1}</td>
                                <td className="text-center">{el.user_name }  {el.user_prenom}</td>
                                <td className="text-center">
                                <div className="d-flex">
                                    <Link
                                      to="#"
                                      className="action-btns1"
                                      data-toggle="modal"
                                      data-target="#taxModalEdit"
                                    >
                                      <i
                                        className="feather feather-edit-2  text-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        onClick={() => handleupdate(el.id)}

                                      />
                                    </Link>
                                  <Link
                                   to="#"
                                   className="action-btns"
                                   data-toggle="tooltip"
                                   data-placement="top"
                                   title={t("Supprimer")}
                                   onClick={() => handleDelete(el.id)}
                                    >
                                      <i className="feather feather-x text-danger" />
                                    </Link>
                                </div>
                                 </td>
                                 </tr>
                                ))}
                                </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ViewCarnetModal getDate={filterByDate} getlist={getData} idCarnet={idCarnet} showModal={showModal} setshowModal={setshowModal}/>

        </div>
      </div>

    </>
  );
}
export default ListCarnetCheque;
