// Imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './DepartementSupport.css'; // Importez le fichier CSS pour les styles
import { Link } from "react-router-dom";
import CustomSyncLoader from '../Others/CustomSyncLoader';
import ErrorCustum from '../Others/ErrorCustum';
import ExportCsv from '../Exports/ExportCsv';
import ExportPdf from '../Exports/ExportPdf';
import CopieContent from '../Exports/CopieContent';
import { Pagination } from 'react-bootstrap';
import PieApexHoliday from '../Charts/CustumApex/PieApexHoliday';
import { useEffect } from 'react';
import { getAllClientsparType } from '../../Services/Facturation/FacturationClientApi';
import { addSupport, deleteSupporById, getAllSupports, support_save_update, updateSupport } from '../../Services/SupportDepartment/SupportApi';
import { toast } from 'react-toastify';
import Card from './Card';
import ExportCsvSupport from '../Exports/ExportCsvSupport';
import { filterProjectByWord, filterProjectByWordSupport } from '../../Utils/SortObject';
import moment from 'moment';
import ButtonHeaderComponent from '../Styles/ButtonHeaderComponent';
import * as XLSX from 'xlsx';
import { BiImport } from "react-icons/bi";

function SupportDataGrid() {
  // Translation
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  //Logical part
  // States
  const [isCollapsed, setisCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [currentData, setcurrentData] = useState([]);
  const [size, setsize] = useState(20);
  const [clients, setClients] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [selectedProject, setSelectedProject] = useState("all");
  const [file, setfile] = useState()
  const [selectYear, setselecteYear] = useState(0)

  // Get all supports 
  const fetchALlSupports = async () => {
    try {
      const response = await getAllSupports()
      setcurrentData(response)
      setallInitialData(response);
      return response;
    }
    catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {

    fetchALlSupports()
  }, [])

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  useEffect(() => {
    // Fetch the list of clients when the component mounts
    async function fetchClients() {
      try {
        const response = await getAllClientsparType();
        setClients(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des clients :', error);
      }
    }

    fetchClients();
  }, [])

  const exportToExcel = () => {
    // Create a new workbook
    let wb = XLSX.utils.book_new();

    // Loop through each groupe to create sheets

    // Data for the current groupe
    let groupeData = clients

    // Create worksheet
    let wsData = [];
    // Header row
    let headers = ['Client', 'Idclient', 'projet', 'nombre_user', 'annee', 'janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre']; // Placeholder for the first cell

    wsData.push(headers);

    // Data rows
    groupeData.forEach(cl => {
      let row = [cl.nom, cl.id];

      wsData.push(row);
    });

    // Convert data to worksheet
    let ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "f1"); // Ensure gp is converted to string for sheet name


    // Export the workbook
    XLSX.writeFile(wb, 'support.xlsx');
    setfile()
  };

  // Projects 
  const projects = [
    "Merbiss",
    "Nomadiss",
    "Clegess"
  ]

  // Add new emty Row
  const addNewRow = () => {
    var newRow = {
      code_generated: localStorage.getItem('code_generated'),
      client: '',
      nombre_user: '',
      projet: '',
      janvier: null,
      fevrier: null,
      mars: null,
      avril: null,
      mai: null,
      juin: null,
      juillet: null,
      aout: null,
      septembre: null,
      octobre: null,
      novembre: null,
      decembre: null,
      annee: 0,
    }
    setcurrentData([
      ...currentData,
      newRow
    ])


  }
  const handleSelectChange = (e, index) => {
    const { value } = e.target;

    let position = e.nativeEvent.target.selectedIndex;
    let nom = e.nativeEvent.target[position].text;
    const list = [...currentData];
    list[index]["client"] = value;
    list[index]["clientName"] = nom; // Ajout de clientName
    setcurrentData(list);

  };

  // Handle project select
  const handleProjectSelectChange = (e, index) => {
    const { value } = e.target;
    let position = e.nativeEvent.target.selectedIndex;
    let project = e.nativeEvent.target[position].text;
    const list = [...currentData];
    list[index]["projet"] = value;
    setcurrentData(list);
  };

  // Handle inputs change
  const onDataChange = (input, inputValue, index) => {
    const list = [...currentData];
    list[index][input] = inputValue;
    setcurrentData(list);
  }


  // Delete Support By Id
  const deleteSupportById = (id, index) => {
    deleteSupporById(id).then((res) => {
      console.log(res);
      var list = currentData.filter(function (el) {
        return el.id !== id;
      });
      setcurrentData(list);
      if (res.success == 1) {

      }
    }).catch((err) => {
      console.log(err);
    })

  }

  // Fetch all supports
  const fetchAllSupports = async () => {
    try {
      const response = await getAllSupports();
      console.log("response for all support :", response)
      setcurrentData(response);
      setallInitialData(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllSupports();
  }, []);
  const filterByProjetctName = async (project) => {
    const P = project === selectedProject ? 'all' : project
    setSelectedProject(P)
    if (P === "all") {

      if (selectYear !== 0) {
        const filtredList = allInitialData.filter((el) => moment(el.annee, 'YYYY').isSame(moment(selectYear, 'YYYY')));
        setcurrentData(filtredList);

      } else {
        setcurrentData(allInitialData);

      }

    } else {
      let filtredList
      if (selectYear === 0) {
        filtredList = allInitialData.filter((el) => el.projet === P);

      } else {
        filtredList = allInitialData.filter((el) => el.projet === P && moment(el.annee, 'YYYY').isSame(moment(selectYear, 'YYYY')));

      }

      setcurrentData(filtredList);
    }
  };


  // Pagination
  /**
 * The function takes in a page of items and sets the currentData state to that page of items
 * @param pageOfItems - an array of items on the current page
 */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };


  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  const handleSaveRow = async (index) => {
    const supportToSave = currentData[index];
    // Validation: Check if a client with the same name already exists in the project
    const isDuplicateClient = currentData.some(
      (el, i) =>
        i !== index &&
        el.projet === supportToSave.projet &&
        el.client === supportToSave.client
    );

    if (isDuplicateClient) {
      toast.error(`Vous n'avez pas le droit d'ajouter deux clients avec le même nom dans meme projet. `);
      return; // Cancel the save
    }


    if (supportToSave.id) {
      // Updating existing row

      updateSupport(supportToSave)
        .then((res) => {
          toast.success(res.message);
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      // Save new row
      // Handle validation
      if (supportToSave.client === "" || supportToSave.projet === "") {
        toast.error("Veuillez remplir tous les champs obligatoires: Client & Projet!.");
      } else {
        try {
          await addSupport(
            localStorage.getItem('code_generated'),
            supportToSave.client,
            supportToSave.nombre_user,
            supportToSave.projet,
            supportToSave.janvier,
            supportToSave.fevrier,
            supportToSave.mars,
            supportToSave.avril,
            supportToSave.mai,
            supportToSave.juin,
            supportToSave.juillet,
            supportToSave.aout,
            supportToSave.septembre,
            supportToSave.octobre,
            supportToSave.novembre,
            supportToSave.decembre,
            supportToSave.annee
          );

          // You may want to handle additional logic or feedback after a successful save
        } catch (error) {
          // Handle error
          console.error("Error saving row:", error);
        }
      }
    }
  };

  const handleSave = async () => {
    const ListSave = [];
    const ListUpdate = []
    var dup = false
    currentData.forEach((element, index) => {
      /* const isDuplicateClient = currentData.some(
            (el, i) =>
              i !== index &&
              el.projet === element.projet &&
              el.client === element.client
          );
    
          if (isDuplicateClient) {
            toast.error(`Vous n'avez pas le droit d'ajouter deux clients avec le même nom dans meme projet.${index}`);
            dup = true;
            return;  // Cancel the save
          } else {
            if (element.projet !== '') {
              if (element.id) {
                ListUpdate.push(element)
              } else {
                ListSave.push(element)
              }
            }
    
          }
          if (element.projet !== '') {
           
          }*/

      const copie = { ...element }
      copie.nombre_user = copie.nombre_user === "" ? 0 : copie.nombre_user
      delete copie.clientName

      if (element.id) {
        ListUpdate.push(copie)
      } else {
        ListSave.push(copie)
      }
    });
    if (!dup) {
      const datasend = { save: ListSave, update: ListUpdate }

      const resp = await support_save_update(datasend)
      if (resp.status === 200) {
        toast.success('données enregistrées')
      } else {

        toast.error('un probleme ')
      }

    }

  }
  const handleSearch = (e) => {
    if (e.target.value) {
      const searchKeyword = e.target.value.toLowerCase();
      const dataSearch = allInitialData.filter((el) =>
        (el.nom && el.nom.toLowerCase().includes(searchKeyword)) ||
        (el.projet && el.projet.toLowerCase().includes(searchKeyword))
      );
      setcurrentData(dataSearch);
    } else {
      setcurrentData(allInitialData);
    }
  };
  const handleSelectAllYears = () => {
    fetchAllSupports();
  };


  // const onChangeDate = () => {
  //   const filteredDate = allInitialData.filter((listed) => { 
  //     const momentDate = moment(listed.annee, 'YYYY'); // Specify the format here
  //     const isSameYear = momentDate.isSameOrAfter(moment(startDate, 'YYYY')) && momentDate.isSameOrBefore(moment(endDate, 'YYYY'));
  //      return isSameYear;
  //   });

  //   setcurrentData(filteredDate);
  // };
  const onChangeDate = (selectedYear) => {
    const filteredDate = allInitialData.filter((listed) => {
      const momentDate = moment(listed.annee, 'YYYY');
      return momentDate.isSame(moment(selectedYear, 'YYYY'));
    });

    setcurrentData(filteredDate);
  };


  const handleSelectDate = (e) => {
    const selectedYear = e.target.value;
    setselecteYear(selectedYear)
    if (selectedYear !== '0') {
      onChangeDate(selectedYear);
    } else {
      handleSelectAllYears();
    }
  };

  const handleFileUpload = () => {

    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Récupérer les en-têtes de colonne depuis la première ligne
      const headers = jsonData[0];

      // Convertir chaque ligne en un objet avec les noms d'attributs appropriés
      const jsonArray = jsonData.slice(1).map(row => {
        const obj = {};

        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      const copiecurrentData = [...currentData]
      // parcourir le json
      /// si code client and projet  remplace par celui de excel 
      const listexcel = jsonArray.map(element => {
        const p = element.projet === undefined ? '' : element.projet
        const findindex = currentData.findIndex(item => parseInt(item.client) === element?.Idclient && item.projet.toLowerCase() === p.toLowerCase());
        if (findindex !== -1) {
          copiecurrentData.splice(findindex, 1)
        }
        const copieelement = { ...element }
        copieelement.client = element?.Idclient?.toString();
        delete copieelement.Client
        copieelement.projet = copieelement.projet === undefined ? '' : copieelement.projet

        delete copieelement.Idclient;
        copieelement.code_generated = localStorage.getItem('code_generated')
        return copieelement;


      });

      const newlist = [...copiecurrentData, ...listexcel]

      setcurrentData(newlist)
      setallInitialData(newlist)

    };
    reader.readAsBinaryString(file);
  };


  // View part
  return (
    <>

      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Département Support")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">


              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <select className="form-control custom-select select2" value={selectYear} onChange={handleSelectDate}>
              <option value={0}>Année</option>
              <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
              <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
              <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
              <option value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</option>
              <option value={new Date().getFullYear() - 4}>{new Date().getFullYear() - 4}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-6">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Département Support")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>
            <div>
              <div className="card-container1 d-flex">

                <Link to="#" className='card1' style={{ color: selectedProject === "Nomadis" ? 'red' : '' }} onClick={() => filterByProjetctName("Nomadis")}>
                  <h3 className='title'>Nomadiss</h3>
                </Link>
                <Link to="#" className='card1' style={{ color: selectedProject === "Cleges" ? 'red' : '' }} onClick={() => filterByProjetctName("Cleges")}>
                  <h3 className='title'>Clegess</h3>
                </Link>
                <Link to="#" className='card1' style={{ color: selectedProject === "Merbiss" ? 'red' : '' }} onClick={() => filterByProjetctName("Merbiss")}>
                  <h3 className='title'>Merbiss</h3>
                </Link>
              </div>
            </div>

            <div className="card-body">

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-3">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={8} className="form-control" id="exampleFormControlSelect1" >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-9">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />



                          </label>
                          <input className="mr-2" type="file" onChange={(e) => setfile(e.target.files[0])} />
                          <span style={{ fontSize: '20px' }} className='mr-2' onClick={handleFileUpload}> <BiImport /></span>
                          <ExportCsvSupport data={currentData} name={"List_Departement_support"} />
                          <ExportPdf data={currentData} name={"List_Departement_support"} columns={["client", "projet", "nombre_user", "annee", "janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre"]} />
                          <CopieContent />
                          <button onClick={exportToExcel} className='btn btn-success'>
                            {t('Exporter en Excel')}
                          </button>{' '}{' '}
                          <button className='btn btn-success' onClick={addNewRow}>
                            {t('Ajouter Support')}
                          </button>

                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5 client-col">{t("Client")}</th>
                              <th className="border-bottom-0 w-5">{t("Projet")}</th>
                              <th className="border-bottom-0 w-5 ">{t("users")}</th>
                              <th className="border-bottom-0 w-5" id='an'>{t("Année")}</th>
                              <th className="border-bottom-0 w-5">{t("Janvier")}</th>
                              <th className="border-bottom-0 w-5">{t("Février")}</th>
                              <th className="border-bottom-0 w-5">{t("Mars")}</th>
                              <th className="border-bottom-0 w-5">{t("Avril")}</th>
                              <th className="border-bottom-0 w-5">{t("Mai")}</th>
                              <th className="border-bottom-0 w-5">{t("Juin")}</th>
                              <th className="border-bottom-0 w-5">{t("Juillet")}</th>
                              <th className="border-bottom-0 w-5">{t("Août")}</th>
                              <th className="border-bottom-0 w-5">{t("Septembre")}</th>
                              <th className="border-bottom-0 w-5">{t("Octobre")}</th>
                              <th className="border-bottom-0 w-5">{t("Novembre")}</th>
                              <th className="border-bottom-0 w-5">{t("Décembre")}</th>
                              <th className="border-bottom-0 w-5">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData ? currentData.map((el, idx) => (
                              <tr key={idx} role="row" className="odd">
                                <td>
                                  {el.id ? (
                                    el.client) :
                                    <select onChange={(e) => handleSelectChange(e, idx)} value={el.client} aria-label={el.client} className="form-control client-col" id="exampleFormControlSelect1">
                                      <option value={"default"}>{el.client}</option>
                                      {clients.map((el) => (
                                        <>

                                          <option value={el.id} key={el.id}>{el.nom}</option>
                                        </>
                                      ))}

                                    </select>
                                  }

                                </td>
                                <td>
                                  <select onChange={(e) => handleProjectSelectChange(e, idx)} value={el.project} aria-label={el.project} className="form-control client-col" id="exampleFormControlSelect1">
                                    <option value={"default"}>{el.projet}</option>
                                    {projects.map((el) => (
                                      <>

                                        <option value={el}>{el}</option>
                                      </>
                                    ))}

                                  </select>
                                </td>
                                <td >
                                  <input type='text' placeholder='N°' value={el.nombre_user} onChange={(e) => onDataChange("nombre_user", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' maxLength="4" placeholder="YYYY" pattern="\d{4}" minLength="4" value={el.annee} onChange={(e) => onDataChange("annee", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.janvier} onChange={(e) => onDataChange("janvier", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.fevrier} onChange={(e) => onDataChange("fevrier", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.mars} onChange={(e) => onDataChange("mars", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.avril} onChange={(e) => onDataChange("avril", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.mai} onChange={(e) => onDataChange("mai", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.juin} onChange={(e) => onDataChange("juin", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.juillet} onChange={(e) => onDataChange("juillet", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.aout} onChange={(e) => onDataChange("aout", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.septembre} onChange={(e) => onDataChange("septembre", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.octobre} onChange={(e) => onDataChange("octobre", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.novembre} onChange={(e) => onDataChange("novembre", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td>
                                  <input type='text' placeholder='N°' value={el.decembre} onChange={(e) => onDataChange("decembre", e.target.value, idx)} className='inputTable'></input>
                                </td>
                                <td className='d-flex align-items-center'>
                                  {(currentData.length - 1 == idx) ?
                                    <Link
                                      to="#"
                                      className="text-success font-18 mr-2"
                                      title="Add"
                                      onClick={() => addNewRow()}
                                    >
                                      <i className="fa fa-plus" />
                                    </Link> : <Link to="#"
                                      className="text-danger font-18 mr-2 "
                                      title="Remove"
                                      onClick={() => deleteSupportById(el.id, idx)}>
                                      <svg className='mt-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1zM7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM7 6v13z" /></svg>
                                    </Link>
                                  }

                                  <Link
                                    to="#"
                                    className="text-primary font-18 mr-2"
                                    title="Save"
                                    onClick={() => handleSaveRow(idx)}
                                  >
                                    <i className="fa fa-save" />
                                  </Link>

                                </td>
                              </tr>
                            )) : ''}

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <button className='btn btn-success' onClick={handleSave}>
                          {t('Enregistrer ')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default SupportDataGrid;
