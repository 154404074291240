import React, { useState } from 'react'

export default function PaieNetBrut() {

 
      // Définir les tranches fiscales pour 2025
      const taxBrackets = [
        { min: 0, max: 5000, rate: 0 },
        { min: 5001, max: 10000, rate: 0.155 },
        { min: 10001, max: 20000, rate: 0.255 },
        { min: 20001, max: 30000, rate: 0.305 },
        { min: 30001, max: 40000, rate: 0.335 },
        { min: 40001, max: 50000, rate: 0.365 },
        { min: 50001, max: 70000, rate: 0.385},
        { min: 70001, max: Infinity, rate: 0.405 }
      ];
      // État pour les entrées de l'utilisateur
      const [net, setNet] = useState('');
      const [months, setMonths] = useState(13);
      const [reductionPercent, setReductionPercent] = useState(10); // Exemple de réduction de 10%
      const [brut, setBrut] = useState(null);
    
      const calculateBrut=()=>{
        const neta = parseFloat(net)*parseFloat(months)
        const netadix = neta*0.1
        let impot =neta*0.305
        if(netadix>2000){
            const impoA = neta-2000+impot 
            const impom = impoA/parseFloat(months)
            const brut = impom/(1-0.0968)
            setBrut(brut)
        }else{
          const impoA = (neta+impot )/0.9
          const impom = impoA/parseFloat(months)
          const brut = impom/(1-0.0968)
          setBrut(brut)
        }

      }
      const calculimpot=(neta)=>{
        const bracket = taxBrackets.find(bracket => neta >= bracket.min && neta <= bracket.max);
        return bracket ? neta*bracket.rate : null;
      }
      return (
        <div className="container">
          <h1>Calcul du Brut Mensuel à partir du Net</h1>
          <div className="form-group">
            <label>Salaire Net Mensuel (TND) :</label>
            <input
              type="number"
              value={net}
              onChange={(e) => setNet(e.target.value)}
              placeholder="Entrez votre salaire net mensuel"
            />
          </div>
          <div className="form-group">
            <label>Nombre de Mois Travaillés :</label>
            <input
              type="number"
              value={months}
              onChange={(e) => setMonths(e.target.value)}
              placeholder="Entrez le nombre de mois"
            />
          </div>
          <div className="form-group">
            <label>Pourcentage de Réduction (%) :</label>
            <input
              type="number"
              value={reductionPercent}
              onChange={(e) => setReductionPercent(e.target.value)}
              placeholder="Entrez le pourcentage de réduction"
            />
          </div>
          <button onClick={calculateBrut}>Calculer Brut</button>
    
          {brut !== null && (
            <div className="result">
              <h3>Brut Mensuel Estimé : {brut.toFixed(2)} TND</h3>
            </div>
          )}
        </div>
      );
    };
    
 