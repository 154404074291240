import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { addCaisse, getCaisseById, updateCaisse } from "../../Services/Pointeuse/CaisseApi";
import { getbanques } from "../../Services/banque";
import { getFacturationClientByCode  , getFacturationFournisseurByCode} from "../../Services/Facturation/FacturationClientApi";
import { toast } from "react-toastify";
import { t } from "i18next";


function AddCaisse(props) {
  const history = useHistory();
  const alert = useAlert();
 
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");
  const [banqueCode, setbanqueCode] = useState("");
  const [databanque, setDatabanque] = useState([]);

  const [date, setdate] = useState(new Date().toISOString().split("T")[0]);
  const [description, setdescription] = useState(null);
  const [cout, setcout] = useState(null);
  const [solde, setsolde] = useState(0);
  const [operation, setoperation] = useState("debit");
  const [debit, setdebit] = useState(0);
  const [credit, setcredit] = useState(0);
  const [type, settype] = useState("espece");
  const [isDebit, setisDebit] = useState(false);
  const [caisse, setcaisse] = useState("Bureau");
  const [selectedOption, setSelectedOption] = useState("Débit");

  useEffect(() => {
  if (props.match.params?.id){
      getCaisseById(props.match.params.id).then((res) => {
        setdate(res.data.date);
        setdescription(res.data.description);
        setoperation(res.data.operation);
        settype(res.data.type);
        setbanqueCode(res.data.banque);
        setsolde(res.data.operation ==="debit"?res.data.montant_sortant : res.data.montant_entrant);
        setcout(res.data.centre_cout);
        setdebit(res.data.montant_entrant);
        setcredit(res.data.montant_sortant);
        setFournisseurCode(res.data.client_code);
        setcaisse(res.data.caisse);
        if (res.data.operation === "debit") {
          setisDebit(false);
        } else {
          setisDebit(true);
        }
      });}
    }, []);
  
  // const options = [
  //   { id: 1, label: 'debit', value: 'debit' },
  //   { id: 2, label: 'credit', value: 'credit' },
  // ];
  

  const handleSubmitEncaissement = (e) => {
    e.preventDefault();
    if (solde===0)
    {
      toast.warning("entrer solde")
      return
    }
    if (operation==="debit"&&fournisseurCode===""){
      toast.warning("choisir fournisseur")
      return 
    }
    if (operation==="credit"&&banqueCode===""){
      toast.warning("choisir banque")
      return 
    } 
    if (props.match.params?.id) {
     updateCaisse(date, fournisseurCode, description, cout, operation, debit, credit, type,banqueCode,solde, props.match.params.id).then((res) => {
          toast.success("Modifié avec succès");
          history.push("/admin/caisse");
        });
      return;
    }
    addCaisse(date, fournisseurCode, description, cout, operation, caisse, debit, credit, type,banqueCode,solde).then((res) => {
      toast.success("Ajouté");
      history.push("/admin/caisse");
    });
  };

  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.target.value);
  };
  const handleChangebanque = (event) => {
    setbanqueCode(event.target.value);
  };

  useEffect(() => {
    getFacturationFournisseurByCode().then((res) => {
      setDataFournisseur(res.data);
      console.log(res.data);
    });
     getbanques() .then((res)=>{setDatabanque(res.data);})
  }, []);

  const handleChangeOperation = (e) => {
    const value = e.target.value;
    setoperation(value);

    if (value === "debit") {
      setdebit(0);
      setisDebit(false);
    } else {
      setcredit(0);
      setisDebit(true);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header border-0">
          <h4 className="card-title">Ajouter Caisse</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
              
              {/* Section Opération */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Opération</label>
                    <select className="form-control" value={operation} required onChange={handleChangeOperation}>
                      <option value="debit">Débit</option>
                      <option value="credit">Crédit</option>
                    </select>
                  </div>
                </div>
  
                <div className="col-md-6">
                  {operation === "debit" ? (
                    <div className="form-group">
                      <label>Fournisseur</label>
                      {dataFournisseur && (
                        <select className="form-control" value={fournisseurCode} onChange={handleChangeFournisseur}>
                          <option value="">Choisir Fournisseur</option>
                          {dataFournisseur.map((option) => (
                            <option key={option.code} value={option.code}>
                              {option.nom} {option.prenom}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Banque </label>
                      {databanque && (
                        <select className="form-control"  value={banqueCode}onChange={handleChangebanque}>
                          <option value=""> Choisir banque </option>
                          {databanque.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.banque}
                            </option>
                           ))}
                        </select>
                      )}
                    </div>
                  )}
                </div>
              </div>
  
              {/* Section Description */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Description</label>
                    <input type="text" value={description} className="form-control" onChange={(e) => setdescription(e.target.value)} />
                  </div>
                </div>
  
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date</label>
                    <input type="date"  
                    value={date} 
                    className="form-control" 
                    required 
                    onChange={(e) => setdate(e.target.value)} />
                  </div>
                </div>
              </div>
  
              {/* Section Solde */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Solde</label>
                    <input type="number" className="form-control" value={solde} onChange={(e) => setsolde(e.target.value)} />
                  </div>
                </div>
              </div>
  
              {/* Bouton de soumission */}
              <button type="submit" className="btn btn-primary">{props.match.params?.id ? t("Modifier") : t("Ajouter")}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}  

export default AddCaisse;
