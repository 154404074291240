import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL_V1 || 'http://localhost:3001/api/v1/';

export const getFacturesPartiellementPayees = async (dateDebut,dateFin) => {
  try {
    console.log("🔵 Appel API :", `${API_URL}dashboard/factures-partiellement-payees`); // Vérifier l'URL appelée
    const response = await axios.get(`${API_URL}dashboard/factures-partiellement-payees/${dateDebut}/${dateFin}`);
    console.log("🟢 Réponse API :", response.data); // Vérifier ce que l'API renvoie
    return response.data;
  } catch (error) {
    console.error("🔴 Erreur API :", error);
  }
};


export const getFacturesNonPayees = async (dateDebut,dateFin) => {
  try {
    const response = await axios.get(`${API_URL}dashboard/factures-non-payees/${dateDebut}/${dateFin}`);
    return response.data;
  } catch (error) {
    console.error("🔴 Erreur API :", error);
  }
};


export const getStatFacturesParMois = async (year) => {
  try {
    console.log("🔵 Appel API :", `${API_URL}Dashboard/stat-factures-par-mois/${year}`);
    const response = await axios.get(`${API_URL}Dashboard/stat-factures-par-mois/${year}`);
    console.log("🟢 Réponse API :", response.data);
    return response.data;
  } catch (error) {
    console.error("🔴 Erreur API :", error);
    return { data: [] };
  }
};

export const getCaHtParClient = async (year) => {
  try {
    console.log("🔵 Appel API :", `${API_URL}Dashboard/ca-ht-par-client/${year}`);
    const response = await axios.get(`${API_URL}Dashboard/ca-ht-par-client/${year}`);
    console.log("🟢 Réponse API :", response.data);
    return response.data;
  } catch (error) {
    console.error("🔴 Erreur API :", error);
    return { data: [] };
  }
};