import React, { useEffect, useState } from "react";
import { addFacturationClient } from "../../../Services/Facturation/FacturationClientApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { useHistory } from "react-router-dom"
import { handleKeyPress_number } from "../../../Utils/InputUtils";
import { getAllDevise } from "../../../Services/devise/devise";
import ReactSelect from "react-select";
import { Modal } from "react-bootstrap";


function AddDiversClientModal({show, setShow , setdatadevis, client , datadevis}){
    console.log(datadevis)
    const [nom, setNom] = useState(datadevis.client_nom);

    const [email, setEmail] = useState(datadevis.client_email);
   
    const [Mobile, setMobile] = useState(datadevis.client_mobile);
  
    const [adrFacturation, setAdrFacturation] = useState(datadevis.client_adresse_facturation);
   

    const [matriculeFiscal, setMatriculeFiscal] = useState(datadevis.client_mf);
    useEffect(()=>{
        setAdrFacturation(datadevis.client_adresse_facturation)
        setEmail(datadevis.client_email)
        setMatriculeFiscal(datadevis.client_mf)
        setNom(datadevis.client_nom)
        setMobile(datadevis.client_mobile)
    },[datadevis])

  
   
   const handleSubmit = () => {
     if ( adrFacturation === '' || nom === '' || Mobile === '' ) {
       toast.warning('Entrez toutes les informations requises')
     } else {
        setdatadevis(prev => ({
            ...prev,
            client_code: client.value,
            remise_pied: client.remise_client,
            client_nom: nom,
            client_mf:matriculeFiscal,
            client_mobile:Mobile,
            client_adresse_facturation: adrFacturation,
            client_email:email,
            adresse: adrFacturation,
            exonere: client.exonere,
            retenue_source: client.rs_id,
          }));
          
          handleClose()
       
       
     }
   };
 
   
   const handleClose = () => setShow(false);

   return (
    <>
         <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Entrez vos informations</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="row " >
        <div className="col-md-12"  >
          <div className="card card-primary">
         
            {/* /.card-header */}
            <div className="card-body">
            <div className="form-group">
                <label>
                  {t("Nom")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" value={nom} onChange={(e) => setNom(e.target.value)} required />
              </div>
              
              <div className="form-group ">
                <label>
                  {t("Email")}
                </label>
                <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>

              <div className="form-group ">
                <label>
                  {t("Mobile")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" value={Mobile} onKeyPress={handleKeyPress_number} onChange={(e) => setMobile(e.target.value)} required />
              </div>

              <div className="form-group">
                <label>
                  {t("Matricule fiscal")} 
                </label>
                <input
                  className="form-control"
                  type="text" value={matriculeFiscal}
                  onChange={(e) => setMatriculeFiscal(e.target.value)}
                  required
                />
              </div>
              
              <div className="form-group">
                <label>
                  {t("Adresse de facturation")} <span className="text-danger">*</span>
                  <small> {t("maximum 40 caractères")}</small>
                </label>
                <input
                  className="form-control"
                  type="text" value={adrFacturation}
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  maxLength="40"
                />
              </div>
              
            
            </div>
            {/* /.card-body */}
           
          </div>
          
        </div>
    
       

    
      </div></Modal.Body>
        <Modal.Footer>
        <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2" onClick={()=>setShow(false)}> 
            <i className="fas fa-trash-alt" /> {t("Annuler")}
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> {t("Enregistrer")}
          </button>
        </div>
      </div>
        </Modal.Footer>
      </Modal>
     

    
    </>
      );
 

}
export default AddDiversClientModal;