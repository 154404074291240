import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Add an encaisement to the database
 * @param banque - the bank account number
 * @param modePaiement - mode de paiement
 * @param numPaiement - the number of the payment
 * @param referenceBanque - The reference of the bank.
 * @param montant - the amount of money
 * @param dateReception - Date of reception of the payment
 * @param dateVersement - Date of payment
 * @param dateEcheance - Date of the payment
 * @param fournisseurCode - The code of the supplier
 * @param factureNum - the invoice number
 * @param factureCode - The code of the facture
 * @param exercice - the year of the invoice
 * @param commentaire - commentaire,
 * @param status - "A" or "V"
 * @returns The response is an object that contains the data that was returned from the server.
 */
export async function addEncaissement(
 data
) {

  try {
    const response = await axios.post(api_url + "facturation/encaissement", data, config);
    console.log(response);
    toast.success(t("encaissement ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It gets the encaissement by code.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "",
 *   "date": "",
 *   "montant": "",
 *   "mode": "",
 *   "numero": "",
 *   "reference": "",
 *   "statut": "",
 *   "type": "",
 *   "user":
 */
export async function getEncaissementByCode(date_debut,date_fin) {
  try {
    const response = await axios.get(api_url + `facturation/encaissement/${getSession("code_generated")}/${date_debut}/${date_fin}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getEncaissementByYear(year) {
  try {
    const response = await axios.get(api_url + `facturation/encaissement/getEncaissementYear/${getSession("code_generated")}/${year}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**** get encaissement par facture */
export async function getEncaissementByFacture(code) {
  try {
    const response = await axios.get(api_url + "facturation/encaissement/getEncaissementByFacture/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * It retrieves the encaissement for a client code. 
 * @param code - The client code
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": [
 *     {
 *       "id": "string",
 *       "clientCode": "string",
 *       "clientName": "string",
 *       "date": "2019-11-01T00:00:00.000Z",
 *       "montant": 0,
 */
export async function getEncaissementByClientCode(code) {
  try {
    const response = await axios.get(api_url + "facturation/encaissement/clientcode/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It retrieves the encaissement with the given id.
 * @param id - The id of the encaisement you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": 1,
 *     "date": "2020-04-01T00:00:00.000Z",
 *     "montant": "1000.00",
 *     "mode": "CASH",
 *     "client": {
 *       "id
 */
export async function getEncaissementById(id) {
  try {
    const response = await axios.get(api_url + "facturation/encaissement/id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It deletes an encaisement by id.
 * @param id - the id of the encaisement to delete
 * @returns The response is an object with the following properties:
 */
export async function deleteEncaissementById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/encaissement/" + id, config);
    console.log(response);
    toast.success(t("encaissement supprimé"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error);
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It edits an existing encaisement.
 * @param banque - the bank account number
 * @param modePaiement - mode de paiement
 * @param numPaiement - the number of the payment
 * @param referenceBanque - The reference of the bank.
 * @param montant - the amount of money
 * @param dateReception - Date of reception of the payment
 * @param dateVersement - Date of payment
 * @param dateEcheance - Date of the payment
 * @param fournisseurCode - the code of the supplier
 * @param factureNum - the invoice number
 * @param factureCode - The code of the facture
 * @param exercice - the year of the payment
 * @param commentaire - commentaire,
 * @param status - status of the payment
 * @param id - id of the encaisement
 * @returns The response is an object that contains the data from the server.
 */
export async function editerEncaissementById(
  banque,
  modePaiement,
  numPaiement,
  referenceBanque,
  montant,
  dateReception,
  dateVersement,
  dateEcheance,
  fournisseurCode,
  factureNum,
  factureCode,
  exercice,
  commentaire,
  status,
  id
) {
  const credentiel = {
    banque: banque,
    mode_paiement: modePaiement,
    numero_piece: numPaiement,
    reference_banque: referenceBanque,
    montant: montant,
    date_reception: dateReception,
    date_versement: dateVersement,
    date_echeance: dateEcheance,
    client: fournisseurCode,
    code_facture: factureCode,
    num_facture: factureNum,
    exercice: exercice,
    commentaire: commentaire,
    status: status,
    id: id,
  };

  try {
    const response = await axios.put(api_url + "facturation/encaissement/", credentiel, config);
    console.log(response);
    toast.success(t("encaissement modifié"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It validates an encaissement.
 * @param validation - true or false
 * @param id - the id of the encaisement to validate
 * @returns The response is an object with the following properties:
 */
export async function validateEncaissementById(validation, id) {
  const credentiel = {
    validation: validation,
    id: id,
  };

  try {
    const response = await axios.put(api_url + "facturation/encaissement/validation/", credentiel, config);
    console.log(response);
    toast.success(t("encaissement validé"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}


