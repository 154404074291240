import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";

/**
 * Composant affichant un graphique de lignes pour comparer les réalisations et les objectifs.
 */
function ObjectifChart1({ objectifs, t }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fusionner les données des objectifs et factures (si nécessaire)
    setData(objectifs);
  }, [objectifs]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
      >
        <defs>
          <linearGradient id="colorRealisationN" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#36f" stopOpacity={0.9} />
            <stop offset="75%" stopColor="#36f" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorRealisationN1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#0dcd94" stopOpacity={0.9} />
            <stop offset="75%" stopColor="#0dcd94" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorObjectif" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#fbc518" stopOpacity={0.9} />
            <stop offset="75%" stopColor="#fbc518" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        <CartesianGrid opacity={0.1} vertical={false} />
        <XAxis dataKey="mois" angle={-45} textAnchor="end" />
        <YAxis />
        <Tooltip />

        {/* Ligne Réalisation N */}
        <Line type="monotone" dataKey="realisation_N" stroke="#36f" fill="url(#colorRealisationN)" />

        {/* Ligne Réalisation N-1 */}
        <Line type="monotone" dataKey="realisation_N_1" stroke="#0dcd94" fill="url(#colorRealisationN1)" />

        {/* Ligne Objectif */}
        <Line type="monotone" dataKey="objectif" stroke="#fbc518 " fill="url(#colorObjectif)" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default ObjectifChart1;
