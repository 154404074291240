import { arondisnumber } from "../../Utils/helper"

export   const calcul_irpp = (param_paie_active,irpp,net, nb_s) => {
        console.log('net',net)
        if (net > 5000) {
            let calcul_irpp_css = 0

            let calcul_irpp = 0
            let copie_net = net
            let copie_net_irpp = net
            //50403.0985
            console.log('irpp',irpp)
            console.log('param_paie_active',param_paie_active.taux_css)

            irpp.forEach(element => {
                const max = parseFloat(element.max)
                const min = parseFloat(element.min)

                if (copie_net > 0) {

                    if (element.max !== null) {
                        const s = max - min;
                        if (copie_net > s) {

                            calcul_irpp_css = calcul_irpp_css + (s * (element.taux + (param_paie_active.taux_css / 100)))
                            copie_net = copie_net - s;

                        } else {

                            calcul_irpp_css = calcul_irpp_css + (copie_net * (element.taux + (param_paie_active.taux_css / 100)))
                            copie_net = copie_net - copie_net;

                        }
                    }
                    else {

                        calcul_irpp_css = calcul_irpp_css + (copie_net * (element.taux + (param_paie_active.taux_css / 100)))
                        copie_net = copie_net - copie_net;


                    }
                }


            });

            irpp.forEach(element => {
                const max = parseFloat(element.max)
                const min = parseFloat(element.min)
                if (copie_net_irpp > 0) {
                    if (element.max !== null) {
                        const s = max - min;
                        if (copie_net_irpp > s) {

                            calcul_irpp = calcul_irpp + (s * (element.taux))
                            copie_net_irpp = copie_net_irpp - s;
                        } else {

                            calcul_irpp = calcul_irpp + (copie_net_irpp * (element.taux))
                            copie_net_irpp = copie_net_irpp - copie_net_irpp;
                        }
                    }
                    else {

                        calcul_irpp = calcul_irpp + (copie_net_irpp * (element.taux))
                        copie_net_irpp = copie_net_irpp - copie_net_irpp;


                    }
                }


            });
            const calcul_irpp_css_mois = arondisnumber((calcul_irpp_css / nb_s), 3);
            const calcul_irpp_mois = arondisnumber((calcul_irpp / nb_s), 3);

            const calcul_irpp_css_annuel = arondisnumber((calcul_irpp_css), 3);
            const calcul_irpp_annuel = arondisnumber((calcul_irpp), 3);

            return { calcul_irpp_css_mois: calcul_irpp_css_mois, calcul_irpp_mois: calcul_irpp_mois, calcul_irpp_css_annuel: calcul_irpp_css_annuel, calcul_irpp_annuel: calcul_irpp_annuel }
        } else {
            return { calcul_irpp_css_mois: 0, calcul_irpp_mois: 0, calcul_irpp_css_annuel: 0, calcul_irpp_annuel: 0 }

        }
    }