import React, { useEffect, useState } from "react";
import axios from "axios";
import { getFacturesNonPayees } from "../../Services/dashboardService";

export default function CardFactureNonPayer() {
  const [totalNetAPayer, setTotalNetAPayer] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [factures, setFactures] = useState([]);
  const getLastMonthStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); // Go to last month
    date.setDate(1); // Set to the first day of that month
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // Function to get tomorrow's date
  const getTomorrowDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1); // Set to tomorrow
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // Initialize state with calculated dates
  const [startDate, setStartDate] = useState(getLastMonthStartDate);
  const [endDate, setEndDate] = useState(getTomorrowDate);

  useEffect(() => {
    fetchData();
  }, []);
const fetchData = async () => {
    const data = await getFacturesNonPayees(startDate, endDate);
    setTotalNetAPayer(data.data.totalNetAPayer);
    setRowCount(data.data.rowCount);

  };


  return (
    <div className="flex justify-center mt-1">
      {/* Carte principale */}
      <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 w-full max-w-4xl">
        <h2 className="text-xl font-semibold text-gray-800 mb-4"> Factures Payées</h2>
        {/* Filtres par date */}
        <div className="row d-flex justify-content-center mb-3">
          <div className="input-group col-4">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="feather feather-calendar" />
              </div>
            </div>
            <input
              type="date"
              className="p-2 border rounded-lg w-1/4"  // Adjusted width for inputs
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div className="input-group col-4">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="feather feather-calendar" />
              </div>
            </div>
            <input
            type="date"
            className="p-2 border rounded-lg w-1/4"  // Adjusted width for inputs
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          </div>

       
          <button className="bg-green-100 text-white px-4 py-2 rounded-lg hover:bg-green-100"
            onClick={fetchData}>
            🔍
          </button>
        </div>

        {/* Totaux */}
        <div className="p-5 bg-gray-100 rounded-lg shadow-md mb-6">
          <div className="bg-blue-100 p-3 rounded-lg">
            <h5 className="text-gray-700">Total Net à Payer:  {totalNetAPayer.toFixed(2)} DT</h5>

          </div>

          <div className="bg-red-100 p-3 rounded-lg">
            <h5 className="text-gray-700">Quantité Factures: {rowCount}</h5>

          </div>
        </div>

        {/* Liste des factures 
        
        <div className="overflow-x-auto">
          <table className="w-full text-left border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3">🧾 Code Facture</th>
                <th className="p-3">💰 Net à Payer (DT)</th>
                <th className="p-3">💵 Montant Encaissé (DT)</th>
              </tr>
            </thead>
            <tbody>
              {factures.length > 0 ? (
                factures.map((facture, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-3">{facture.code_facture}</td>
                    <td className="p-3 text-blue-600">{facture.net_a_payer.toFixed(2)}</td>
                    <td className="p-3 text-green-600">{facture.total_montant_encaisse.toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center p-4 text-gray-500">
                    Aucune facture trouvée 🧐
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>*/}
      </div>
    </div>
  );
}
