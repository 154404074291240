import { useEffect } from "react";
import Projet from "./Components/Projet";
import Tasks from "./Components/Tasks";
import DashboardLayout from "./layout/DashboardLayout";
import AppRoute from "./Routes/AppRoute";
import { BrowserRouter, Link, Redirect, Switch } from "react-router-dom";
import EmptyLayout from "./layout/EmptyLayout";
import LoginLayout from "./layout/LoginLayout";
import PageNotFound from "./Pages/PageNotFound";
import SignIn from "./Pages/SignIn";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import ListUsers from "./Components/Users/ListUsers";
import ListVaccin from "./Components/Vaccination/ListVaccin";
import EvaxInfo from "./Components/Vaccination/EvaxInfo";
import Holiday from "./Components/Holiday/Holiday";
import EditUser from "./Components/Users/EditUser";
import EditerVaccin from "./Components/Vaccination/EditerVaccin";
import AddVaccin from "./Components/Vaccination/AddVaccin";
import EditerHoliday from "./Components/Holiday/EditerHoliday";
import CongeCalendar from "./Components/Conges/CongeCalendar";
import DemandeConges from "./Components/Conges/DemandeConges";
import ValideConges from "./Components/Conges/ValideConges";
import NonValideConges from "./Components/Conges/NonValideConges";
import PointageToday from "./Components/Pointages/PointageToday";
import ListTeletravail from "./Components/teletravail/ListTeletravail";
import Autorisation from "./Components/Autorisation/Autorisation";
import ListAcompte from "./Components/Acompte/ListAcompte";
import AnnonceList from "./Components/Annonce/AnnonceList";
import AnnonceDetail from "./Components/Annonce/AnnonceDetail";
import Support from "./Components/Support/Support";
import Blog from "./Components/Blog/Blog";
import ListFiles from "./Components/FileManger/ListFiles";
import ProductList from "./Components/Facturation/Produit/ProductList";
import EditProduct from "./Components/Facturation/Produit/EditProduct";
import AddProduct from "./Components/Facturation/Produit/AddProduct";
import Entreprise from "./Components/Entreprise/Entreprise";
import Baremes from "./Components/Entreprise/Baremes";
import ListSalaire from "./Components/Virement/ListSalaire";
import EditerVirement from "./Components/Virement/EditerVirement";
import VirementSalaire from "./Components/Virement/VirementSalaire";
import Objectif from "./Components/Objectif/Objectif";
import ListDepartement from "./Components/Departement/ListDepartement";
import EditDeparement from "./Components/Departement/EditDepartement";
import KanbanTasks from "./Components/Tasks/KanbanTasks";
import ListTasks from "./Components/Tasks/ListTasks";
import ListProjects from "./Components/Projects/ListProjects";
import ListTodo from "./Components/Todo/ListTodo";
import ListDemandeMaintenance from "./Components/Maintenance/ListDemandeMaintenance";
import ListDemandeMateriel from "./Components/Materiel/ListDemandeMateriel";
import ListClient from "./Components/Facturation/Client/ListClient";
import EditerClient from "./Components/Facturation/Client/EditerClient";
import AddClient from "./Components/Facturation/Client/AddClient";
import ListFournisseur from "./Components/Facturation/Fournisseur/ListFournisseur";
import EditerFournisseur from "./Components/Facturation/Fournisseur/EditerFournisseur";
import AddFournisseur from "./Components/Facturation/Fournisseur/AddFournisseur";
import Tax from "./Components/Facturation/Tax/Tax";
import EditTax from "./Components/Facturation/Tax/EditTax";
import Prefix from "./Components/Facturation/Prefix/Prefix";
import ListEncaissement from "./Components/Facturation/Encaissement/ListEncaissement";
import AddEncaissement from "./Components/Facturation/Encaissement/AddEncaissement";
import { editerEncaissementById } from "./Services/Facturation/FacturationEncaissementApi";
import EditEncaissement from "./Components/Facturation/Encaissement/EditEncaissement";
import AddPaiement from "./Components/Facturation/Paiement/AddPaiement";
import ListPaiement from "./Components/Facturation/Paiement/ListPaiement";
import EditerPaiement from "./Components/Facturation/Paiement/EditerPaiement";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AjoutFactureVente from "./Components/Facturation/Document/Vente/Facture/AjoutFactureVente";
import EditerFactureVente from "./Components/Facturation/Document/Vente/Facture/EditerFactureVente";
import AjoutBlVente from "./Components/Facturation/Document/Vente/BL/AjoutBlVente";
import AjoutDevisVente from "./Components/Facturation/Document/Vente/Devis/AjoutDevisVente";
import AjoutCommandeVente from "./Components/Facturation/Document/Vente/Commande/AjoutCommandeVente";
import AjoutFactureAchat from "./Components/Facturation/Document/Achat/Facture/AjoutFactureAchat";
import AjoutBlAchat from "./Components/Facturation/Document/Achat/BL/AjoutBlAchat";
import AjoutDevisAchat from "./Components/Facturation/Document/Achat/Devis/AjoutDevisAchat";
import AjoutCommandeAchat from "./Components/Facturation/Document/Achat/Commande/AjoutCommandeAchat";
import ListFactureVente from "./Components/Facturation/Document/Vente/Facture/ListFactureVente";
import ListBlVente from "./Components/Facturation/Document/Vente/BL/ListBlVente";
import ListDevisVente from "./Components/Facturation/Document/Vente/Devis/ListDevisVente";
import ListCommandeVente from "./Components/Facturation/Document/Vente/Commande/ListCommandeVente";
import ListFactureAchat from "./Components/Facturation/Document/Achat/Facture/ListFactureAchat";
import ListCommandeAchat from "./Components/Facturation/Document/Achat/Commande/ListCommandeAchat";
import ListDevisAchat from "./Components/Facturation/Document/Achat/Devis/ListDevisAchat";
import ListBlAchat from "./Components/Facturation/Document/Achat/BL/ListBlAchat";
import EditerBlVente from "./Components/Facturation/Document/Vente/BL/EditerBlVente";
import EditerDevisVente from "./Components/Facturation/Document/Vente/Devis/EditerDevisVente";
import EditerCommandeVente from "./Components/Facturation/Document/Vente/Commande/EditerCommandeVente";
import EditerFactureAchat from "./Components/Facturation/Document/Achat/Facture/EditerFactureAchat";
import EditerBlAchat from "./Components/Facturation/Document/Achat/BL/EditerBlAchat";
import EditerDevisAchat from "./Components/Facturation/Document/Achat/Devis/EditerDevisAchat";
import EditerCommandeAchat from "./Components/Facturation/Document/Achat/Commande/EditerCommandeAchat";
import GlobalTable from "./Containers/Table/GlobalTable";
import Welcome from "./Components/welcome/Welcome";
import ListQuestionnaire from "./Components/Questionnaire/ListQuestionnaire";
import ListRetard from "./Components/Retard/ListRetard";
import Message from "./Components/Chat/Message";
import firebase from "./firebase";
import { GantChart } from "./Components/Charts/GoogleCharts/GantChart";
import ViewTask from "./Components/Tasks/ViewTask";
import ClientActivity from "./Components/Facturation/ClientActivity/ClientActivity";
import "./App.css";
import socket from "./Connection/ws";
import Test from "./Components/Test";
import Search from "./Components/Search";
import { getSession, isLogin } from "./Utils/SessionUtils";
import toast from "react-hot-toast";
import { getUserById } from "./Services/Pointeuse/UsersApi";
import ImageComponent from "./Components/Styles/ImageComponent";
import { Howl } from "howler";

import audio from "./sounds/msg.mp3";
import PointageManquant from "./Components/Pointages/PointageManquant";
import PrintConge from "./Components/Conges/PrintConge";
import WizardConfig from "./Components/Config/WizardConfig";
import LandingPrice from "./Components/Price/LandingPrice";
import { getAllSession, getSessionByCode } from "./Services/Pointeuse/SessionApi";
import DechargesConge from "./Components/Conges/DechargesConge";
import SuccessPage from "./Pages/SuccessPage";
import UploadDecharge from "./Components/Facturation/UploadDecharge";
import Configuration from "./Components/Settings/Configuration";
import PrivateRouteLogin from "./Routes/PrivateRouteLogin";
import { PointageNotification } from "./Components/Notification/PointageNotification";
import { CongeNotification } from "./Components/Notification/CongeNotification";
import { AutorisationNotification } from "./Components/Notification/AutorisationNotification";
import { RetardNotification } from "./Components/Notification/RetardNotification";
import { QuestionnaireNotification } from "./Components/Notification/QuestionnaireNotification";
import { ChatNotification } from "./Components/Notification/ChatNotification";
import ProductParams from "./Components/Facturation/Produit/ProductParams";
import EditProjet from "./Components/Projects/EditProjet";
import RetardCalendar from "./Components/Retard/RetardCalendar";
import EditTicket from "./Components/Tickets/EditTicket";
import ListTickets from "./Components/Tickets/ListTickets";
import AddTicket from "./Components/Tickets/AddTicket";
import AddCheque from "./Components/Cheque/AddCheque";
import ListCheque from "./Components/Cheque/ListCarnetCheque";
import UpdateCheque from "./Components/Cheque/UpdateCheque";
import ViewQuestionnaire from "./Components/Questionnaire/ViewQuestionnaire";
import ListCaisse from "./Components/Caisse/ListCaisse";
import AddCaisse from "./Components/Caisse/AddCaisse";
import EditCaisse from "./Components/Caisse/EditCaisse";
import ListBanque from "./Components/Banque/ListBanque";
import AddBanque from "./Components/Banque/AddBanque";
import EditBanque from "./Components/Banque/EditBanque";
import ListChequeEmis from "./Components/ChequeEmis/ListChequeEmis";
 import Deplacement from "./Components/Deplacement/Deplacement";
import Attestation_Travail from "./Components/AttestationTravail/Attestation_Travail";
import PointageTest from "./Components/Pointages/PointageTest";
import ListFactureClients from "./Components/Facturation/DetailsFactures/ListFactureClients";
import PrintAttestation from "./Components/AttestationTravail/PrintAttestation";
import NumeroSerie from "./Components/Facturation/Produit/NumeroSerie";
import ListAbonnement from "./Components/Facturation/Document/Vente/abonnement/ListAbonnement";
import AjouterAbonnement from "./Components/Facturation/Document/Vente/abonnement/AjouterAbonnement";
import EditerAbonnement from "./Components/Facturation/Document/Vente/abonnement/EditerAbonnement";
 import ListSatisfaction from "./Components/Satisfaction/ListSatisfaction";
import AjouterSatisfaction from "./Components/Satisfaction/AjouterSatisfaction";
import DepartementSupport from "./Components/DepartementSupport/DepartementSupport";
import supportDataGrid from "./Components/DepartementSupport/SupportDataGrid";
import creeTask from "./Components/Tasks/creeTask";
import CalculJourTravail from "./Components/Paie/CalculJourTravail";
import PaiePointageMonth from "./Components/Paie/PaiePointageMonth";
import ListLigneFacturer from "./Components/Facturation/Document/Vente/abonnement/ListLigneFacturer";
import DashboardLayoutUser from "./layout/DashboardLayoutUser";
import DashbordUser from "./Pages/DashbordUser";
import ReunionClient from "./Components/ReunionCLient/ReunionClient";
import ListReunionClient from "./Components/ReunionCLient/ListReunionClient";
import AjoutAvoirAchat from "./Components/Facturation/Document/Achat/avoir/AjoutAvoirAchat";
import AjoutAvoirVente from "./Components/Facturation/Document/Vente/avoir/AjoutAvoirVente";
import ListAvoirAchat from "./Components/Facturation/Document/Achat/avoir/ListAvoirAchat";
import ListAvoirVente from "./Components/Facturation/Document/Vente/avoir/ListAvoirVente";
import Etatstock from "./Components/Facturation/Document/stock/EtatStock/Etatstock";
import Inventaire from "./Components/Facturation/Document/stock/Inventaire/Inventaire";
import ListInventaire from "./Components/Facturation/Document/stock/Inventaire/ListInventaire";
import LigneInventaire from "./Components/Facturation/Document/stock/Inventaire/LigneInventaire";
import ListBanques from "./Components/ListBanques/ListBanques";
import UploadFacture from "./Components/Facturation/UploadFacture";
import Exercice from "./Components/Entreprise/Exercice";
import ParametragePaie from "./Components/Entreprise/parametrage_paie/ParametragePaie";
import Societepage from "./Components/Entreprise/Societepage";
import MatricePaie from "./Components/Paie/Calcul_paie/MatricePaie";
import Fichepaie from "./Components/Paie/files/Fichepaie";

import AccueilPaie from "./Components/Paie/Calcul_paie/AccueilPaie";
import CreeTaches from "./Components/Tache/CreeTaches";
import ViewClientTaches from "./Components/Tache/ViewClientTaches";
import ViewTaches from "./Components/Tache/ViewTaches";
import DeclarationCnss from "./Components/DeclarationCnss/DeclarationCnss";
import TeleTravailCalendar from "./Components/teletravail/TeleTravailCalendar";
import ReportImpot from "./Components/Paie/ReportImpot/ReportImpot";
import PaieNetBrut from "./Components/Paie/Calcul_paie/PaieNetBrut";
import CarnetCheque from "./Components/Cheque/CarnetCheque";
import AddCarnetCheque from "./Components/Cheque/AddCarnetCheque";
import ListCheques from "./Components/Cheque/ListCheques";
import ListCarnetCheque from "./Components/Cheque/ListCarnetCheque";
import DashboardComptable from "./Pages/DashboardComptable";
import ComptableDashboard from "./Pages/ComptableDashboard";

function App() {
  const PointageTodaySocket = () => <PointageToday socket={socket} />;

  useEffect(() => {
    PointageNotification();
    QuestionnaireNotification();
    ChatNotification();
    RetardNotification();
    AutorisationNotification();
    CongeNotification();
  }, []);

  useEffect(() => {}, []);

  // useEffect(() => {
  //   socket.on("login", (result) => {
  //     if (result.code_generated == getSession("code_generated")) {
  //       getAllSession().then((res) => {
  //         const login = res.data[0];
  //         console.log(login.code);
  //         console.log(getSession("codeCnx"));
  //         if (login.code != getSession("codeCnx")) {
  //           console.log("deconnect");
  //           localStorage.clear();
  //           localStorage.setItem("isLoginPointeuse", false);
  //           window.location.reload();
  //         } else {
  //           console.log("not deconnect");
  //         }
  //       });
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("pointeuse_dark") === "true") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, []);

  useEffect(() => {
    try {
      const messaging = firebase.messaging();
      const topic = localStorage.getItem("code_generated") + "admin";

      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          subscribeTokenToTopic(token, topic);
          subscribeTokenToTopic(token, localStorage.getItem("code_generated") + "user" + localStorage.getItem("id"));
        })
        .catch((err) => {
          console.log(err);
        });
      messaging.onMessage((payload) => {
        //showNotification(payload);
      });
    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }, []);

  /**
   * Subscribe a token to a topic
   * @param token - The token of the device you want to subscribe to the topic.
   * @param topic - The name of the topic to which you want to subscribe.
   */
  function subscribeTokenToTopic(token, topic) {
   /* const fcm_server_key =
      "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";
    fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
      method: "POST",
      headers: new Headers({
        Authorization: "key=" + fcm_server_key,
      }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw "Error subscribing to topic: " + response.status + " - " + response.text();
        }
      })
      .catch((error) => {
        console.error(error);
      });*/
  }

  /**
   * `showNotification` is a function that takes a payload object and uses it to create a new
   * notification
   * @param payload - The payload of the message.
   */
  const showNotification = (payload) => {
    var options = {
      body: payload.data.message,
      icon: "/dist/img/logo.png?    auto=compress&cs=tinysrgb&dpr=1&w=500",
      dir: "ltr",
    };
    new Notification(payload.data.title, options);
  };

  return (
    
    <BrowserRouter>
      <Switch>
        <AppRoute layout={EmptyLayout} component={Welcome} path="/welcome" exact />
        <AppRoute layout={EmptyLayout} component={LandingPrice} path="/price" exact />
        <AppRoute layout={EmptyLayout} component={CreeTaches} path="/CreeTache/:clientCode" exact />
        <AppRoute layout={EmptyLayout} component={ViewClientTaches} path="/ViewClientTaches/:clientCode" exact />

        <AppRoute layout={EmptyLayout} component={SuccessPage} path="/success" exact />
        <PrivateRoute layout={DashboardLayout} component={Tasks} path="/test" exact />
        <PrivateRoute layout={DashboardLayout} component={Projet} path="/" exact />
        <PrivateRoute layout={DashboardLayout} component={ListUsers} path="/admin/users" exact />
        <PrivateRoute path="/admin/users/:id" layout={DashboardLayout} component={EditUser} />
        <PublicRoute layout={LoginLayout} component={SignIn} path="/login" exact />
        <PrivateRoute path="/admin/vaccin" layout={DashboardLayout} component={ListVaccin}></PrivateRoute>
        <PrivateRoute path="/admin/addvaccin" layout={DashboardLayout} component={AddVaccin}></PrivateRoute>
        <PrivateRoute path="/admin/editvaccin/:id" layout={DashboardLayout} component={EditerVaccin}></PrivateRoute>
        <PrivateRoute layout={DashboardLayout} component={EvaxInfo} path="/evaxinfo" />
        <PrivateRoute path="/admin/holiday" layout={DashboardLayout} component={Holiday} />
        <PrivateRoute path="/admin/editholiday/:id" layout={DashboardLayout} component={EditerHoliday} />
        <PrivateRoute path="/admin/calendarconge" layout={DashboardLayout} component={CongeCalendar} />
        <PrivateRoute path="/admin/demandeconge" layout={DashboardLayout} component={DemandeConges} />
        <PrivateRoute path="/admin/congevalide" layout={DashboardLayout} component={ValideConges} />
        <PrivateRoute path="/admin/congenonvalide" layout={DashboardLayout} component={NonValideConges} />
        <PrivateRoute path="/admin/jourpointage" layout={DashboardLayout} component={PointageTodaySocket} />
        <PrivateRoute path="/admin/moispointage" layout={DashboardLayout} component={PointageTest} />
        <PrivateRoute path="/admin/pointagemanquant" layout={DashboardLayout} component={PointageManquant} />
        <PrivateRoute path="/admin/teletravail" layout={DashboardLayout} component={ListTeletravail}></PrivateRoute>
        <PrivateRoute path="/admin/teleTravailcalendar" layout={DashboardLayout} component={TeleTravailCalendar}></PrivateRoute>
        <PrivateRoute path="/admin/PaieNetBrut" layout={DashboardLayout} component={PaieNetBrut}></PrivateRoute>

        <PrivateRoute path="/admin/autorisation" layout={DashboardLayout} component={Autorisation} />
         <PrivateRoute path="/admin/deplacement" layout={DashboardLayout} component={Deplacement} />
        <PrivateRoute path="/admin/attestation_travail" layout={DashboardLayout} component={Attestation_Travail} />
        <PrivateRoute path="/admin/print_attestationt/:id" layout={DashboardLayout} component={PrintAttestation}></PrivateRoute>
        <PrivateRoute path="/admin/acomptesalaire" layout={DashboardLayout} component={ListAcompte}></PrivateRoute>
        <PrivateRoute path="/admin/annonce" exact layout={DashboardLayout} component={AnnonceList} />,
        <PrivateRoute path="/admin/annoncedetail/:id" layout={DashboardLayout} component={AnnonceDetail} />
        <PrivateRoute path="/admin/support" layout={DashboardLayout} component={Support} />
        <PrivateRoute path="/admin/blog" layout={DashboardLayout} component={Blog} />
        <PrivateRoute path="/admin/fileconge" layout={DashboardLayout} component={ListFiles} />
        <PrivateRoute path="/admin/produit" exact layout={DashboardLayout} component={ProductList} />
        <PrivateRoute path="/admin/produit/:id" layout={DashboardLayout} component={EditProduct} />
        <PrivateRoute path="/admin/addproduct" layout={DashboardLayout} component={AddProduct} />
        <PrivateRoute path="/admin/productparams" layout={DashboardLayout} component={ProductParams} />
        <PrivateRoute path="/admin/numero_serie" layout={DashboardLayout} component={NumeroSerie} />
        <PrivateRoute path="/admin/entreprise" layout={DashboardLayout} component={Societepage} />
        <PrivateRoute path="/admin/baremes" layout={DashboardLayout} component={Baremes} />
        <PrivateRoute path="/admin/salaire" exact layout={DashboardLayout} component={ListSalaire}></PrivateRoute>,
        <PrivateRoute path="/admin/virementsalaire" layout={DashboardLayout} component={VirementSalaire} />
        <PrivateRoute path="/admin/objectif" layout={DashboardLayout} component={Objectif}></PrivateRoute>
        <PrivateRoute path="/admin/satisfaction" layout={DashboardLayout} component={ListSatisfaction}></PrivateRoute>
        <PrivateRoute path="/admin/ajoutsatisfaction" layout={DashboardLayout} component={AjouterSatisfaction}></PrivateRoute>
        <PrivateRoute path="/admin/editsatisfaction" layout={DashboardLayout} component={AjouterSatisfaction}></PrivateRoute>

        <PrivateRoute path="/admin/salaire/:id" exact layout={DashboardLayout} component={EditerVirement}></PrivateRoute>
        <PrivateRoute path="/admin/departements" layout={DashboardLayout} component={ListDepartement}></PrivateRoute>,
        <PrivateRoute path="/admin/editerdepartement/:id" layout={DashboardLayout} component={EditDeparement}></PrivateRoute>
        <PrivateRoute path="/admin/departementsupport" layout={DashboardLayout} component={DepartementSupport}></PrivateRoute>
        <PrivateRoute path="/admin/supportManagement" layout={DashboardLayout} component={supportDataGrid}></PrivateRoute>

        <PrivateRoute path="/admin/kanban" layout={DashboardLayout} component={KanbanTasks} />
       <PrivateRoute path="/admin/task" exact layout={DashboardLayout} component={ListTasks} />
        <PrivateRoute path="/admin/viewtask/:id" layout={DashboardLayout} component={ViewTask} />
        <PrivateRoute path="/admin/creetask" layout={DashboardLayout} component={creeTask} />
  
        <PrivateRoute path="/admin/projects" exact layout={DashboardLayout} component={ListProjects} />
        <PrivateRoute path="/admin/projects/:id" layout={DashboardLayout} component={EditProjet} />
        <PrivateRoute path="/admin/todo" layout={DashboardLayout} component={ListTodo} />
        <PrivateRoute path="/admin/maintenance" layout={DashboardLayout} component={ListDemandeMaintenance}></PrivateRoute>
        <PrivateRoute path="/admin/materiel" exact layout={DashboardLayout} component={ListDemandeMateriel}></PrivateRoute>
        <PrivateRoute path="/admin/client" exact layout={DashboardLayout} component={ListClient} />
        <PrivateRoute path="/admin/client/:id" layout={DashboardLayout} component={EditerClient} />
        <PrivateRoute path="/admin/addclient" layout={DashboardLayout} component={AddClient} />
        <PrivateRoute path="/admin/fournisseur" exact layout={DashboardLayout} component={ListFournisseur} />
        <PrivateRoute path="/admin/fournisseur/:id" layout={DashboardLayout} component={EditerFournisseur} />
        <PrivateRoute path="/admin/addfournisseur" layout={DashboardLayout} component={AddFournisseur} />
        <PrivateRoute path="/admin/abonnement" exact layout={DashboardLayout} component={ListFactureClients} />
        <PrivateRoute path="/admin/tax" exact layout={DashboardLayout} component={Tax} />,
        <PrivateRoute path="/admin/tax/:id" layout={DashboardLayout} component={EditTax} />
        <PrivateRoute path="/admin/prefix" layout={DashboardLayout} component={Prefix} />
        <PrivateRoute path="/admin/encaissement" layout={DashboardLayout} component={ListEncaissement} />,
        <PrivateRoute path="/admin/addencaissement" layout={DashboardLayout} component={AddEncaissement} />,
        <PrivateRoute path="/admin/facturation/editencaissement/:id" layout={DashboardLayout} component={EditEncaissement} />
        <PrivateRoute path="/admin/addpaiement" layout={DashboardLayout} component={AddPaiement}></PrivateRoute>,
        <PrivateRoute path="/admin/paiement" layout={DashboardLayout} component={ListPaiement}></PrivateRoute>,
        <PrivateRoute path="/admin/facturation/editpaiement/:id" layout={DashboardLayout} component={EditerPaiement}></PrivateRoute>
        <PrivateRoute path="/admin/ajoutfacturevente" layout={DashboardLayout} component={EditerFactureVente} />
        <PrivateRoute path="/admin/ajoutabonnement" layout={DashboardLayout} component={AjouterAbonnement} />
        <PrivateRoute path="/admin/ajoutblvente" layout={DashboardLayout} component={AjoutBlVente} />,
        <PrivateRoute path="/admin/ajoutdevisvente" layout={DashboardLayout} component={AjoutDevisVente} />,
        <PrivateRoute path="/admin/ajoutcommandevente" layout={DashboardLayout} component={AjoutCommandeVente} />,
        <PrivateRoute path="/admin/ajoutfactureachat" layout={DashboardLayout} component={AjoutFactureAchat} />,
        <PrivateRoute path="/admin/ajoutblachat" layout={DashboardLayout} component={AjoutBlAchat} />,
        <PrivateRoute path="/admin/ajoutdevisachat" layout={DashboardLayout} component={AjoutDevisAchat} />,
        <PrivateRoute path="/admin/ajoutcommandeachat" layout={DashboardLayout} component={AjoutCommandeAchat} />,
        <PrivateRoute path="/admin/facturevente" layout={DashboardLayout} component={ListFactureVente} />
        <PrivateRoute path="/admin/commandevente" layout={DashboardLayout} component={ListCommandeVente} />
        <PrivateRoute path="/admin/listabonnement" layout={DashboardLayout} component={ListAbonnement} />
        <PrivateRoute path="/admin/abonnementvente/:id?" layout={DashboardLayout} component={EditerAbonnement} />
        <PrivateRoute path="/admin/devisvente" layout={DashboardLayout} component={ListDevisVente} />
        <PrivateRoute path="/admin/blvente" layout={DashboardLayout} component={ListBlVente} />
        <PrivateRoute path="/admin/factureachat" layout={DashboardLayout} component={ListFactureAchat} />
        <PrivateRoute path="/admin/commandeachat" layout={DashboardLayout} component={ListCommandeAchat} />
        <PrivateRoute path="/admin/devisachat" layout={DashboardLayout} component={ListDevisAchat} />
        <PrivateRoute path="/admin/blachat" layout={DashboardLayout} component={ListBlAchat} />
        <PrivateRoute path="/admin/ajoutavoirachat" layout={DashboardLayout} component={AjoutAvoirAchat} />
        <PrivateRoute path="/admin/editeravoirachat/:id" layout={DashboardLayout} component={AjoutAvoirAchat} />
        <PrivateRoute path="/admin/ajoutavoirvente" layout={DashboardLayout} component={AjoutAvoirVente} />
        <PrivateRoute path="/admin/ListAvoirVente" layout={DashboardLayout} component={ListAvoirVente} />
        <PrivateRoute path="/admin/editeravoirvente/:id" layout={DashboardLayout} component={AjoutAvoirVente} />
        <PrivateRoute path="/admin/Etatstock" layout={DashboardLayout} component={Etatstock} />
        <PrivateRoute path="/admin/inventaire" layout={DashboardLayout} component={Inventaire} />
        <PrivateRoute path="/admin/listinventaire" layout={DashboardLayout} component={ListInventaire} />
        <PrivateRoute path="/admin/lignesinventaire/:id/:validation" layout={DashboardLayout} component={LigneInventaire} />

        <PrivateRoute path="/admin/ListAvoirAchat" layout={DashboardLayout} component={ListAvoirAchat} />
        <PrivateRoute path="/admin/editerblvente/:id" layout={DashboardLayout} component={AjoutBlVente} />,
        <PrivateRoute path="/admin/editerdevisvente/:id" layout={DashboardLayout} component={AjoutDevisVente} />,
        <PrivateRoute path="/admin/editercommandevente/:id" layout={DashboardLayout} component={AjoutCommandeVente} />,
        <PrivateRoute path="/admin/editerfacturevente/:id" layout={DashboardLayout} component={EditerFactureVente} />,
         <PrivateRoute path="/admin/editerfactureachat/:id" layout={DashboardLayout} component={AjoutFactureAchat} />,
        <PrivateRoute path="/admin/editerblachat/:id" layout={DashboardLayout} component={AjoutBlAchat} />,
        <PrivateRoute path="/admin/editerdevisachat/:id" layout={DashboardLayout} component={AjoutDevisAchat} />,
        <PrivateRoute path="/admin/editercommandeachat/:id" layout={DashboardLayout} component={AjoutCommandeAchat} />,
        <PrivateRoute path="/admin/global" layout={DashboardLayout} component={GlobalTable} />
        <PrivateRoute path="/admin/questionnaire" exact layout={DashboardLayout} component={ListQuestionnaire} />
        <PrivateRoute path="/admin/questionnaire/:id" exact layout={DashboardLayout} component={ViewQuestionnaire} />
        <PrivateRoute path="/admin/retard" layout={DashboardLayout} component={ListRetard} />
        <PrivateRoute path="/admin/chat/:id" layout={DashboardLayout} component={Message} />
        <PrivateRoute path="/admin/gantt" layout={DashboardLayout} component={GantChart} />
        <PrivateRoute path="/admin/clientactivity/:id" layout={DashboardLayout} component={ClientActivity}></PrivateRoute>
        <PrivateRoute path="/admin/search" layout={DashboardLayout} component={Search} />
        <PrivateRoute path="/admin/congeprint/:id" layout={DashboardLayout} component={PrintConge}></PrivateRoute>
        <PrivateRouteLogin path="/admin/config" layout={EmptyLayout} component={WizardConfig}></PrivateRouteLogin>
        <PrivateRoute path="/admin/decharge" layout={DashboardLayout} component={DechargesConge}></PrivateRoute>
        <PrivateRoute path="/admin/facturation/uploaddecharge/:id" layout={DashboardLayout} component={UploadDecharge}></PrivateRoute>
        <PrivateRoute path="/admin/uploadfacture/:id" layout={DashboardLayout} component={UploadFacture}></PrivateRoute>
        <PrivateRoute path="/admin/DashboardComptable" layout={DashboardLayout} component={DashboardComptable}></PrivateRoute>
        <PrivateRoute path="/admin/ComptableDashboard" layout={DashboardLayout} component={ComptableDashboard}></PrivateRoute>

        <PrivateRoute path="/admin/configuration" layout={DashboardLayout} component={Configuration}></PrivateRoute>
        <PrivateRoute path="/admin/retardcalendar/:id" layout={DashboardLayout} component={RetardCalendar}></PrivateRoute>
        <PrivateRoute path="/admin/ticket/:id" layout={DashboardLayout} component={EditTicket}></PrivateRoute>
        <PrivateRoute path="/admin/ticket" layout={DashboardLayout} component={ListTickets}></PrivateRoute>
        <PrivateRoute path="/admin/ticket/:id" layout={DashboardLayout} component={EditTicket}></PrivateRoute>
        <PrivateRoute path="/admin/addticket" layout={DashboardLayout} component={AddTicket}></PrivateRoute>
        <PrivateRoute path="/admin/addcheque" layout={DashboardLayout} component={AddCheque}></PrivateRoute>
        <PrivateRoute path="/admin/addcarnetcheque" layout={DashboardLayout} component={CarnetCheque}></PrivateRoute>
  
        <PrivateRoute path="/admin/cheque" layout={DashboardLayout} component={ListCheques}></PrivateRoute>
        <PrivateRoute path="/admin/Carnetcheque" layout={DashboardLayout} component={ListCarnetCheque}></PrivateRoute>

        <PrivateRoute path="/admin/editcheque/:id" layout={DashboardLayout} component={AddCheque}></PrivateRoute>

        <PrivateRoute path="/admin/updatecheque/:id" layout={DashboardLayout} component={UpdateCheque}></PrivateRoute>
        <PrivateRoute path="/admin/caisse" exact layout={DashboardLayout} component={ListCaisse}></PrivateRoute>
        <PrivateRoute path="/admin/ajoutcaisse" layout={DashboardLayout} component={AddCaisse}></PrivateRoute>
        <PrivateRoute path="/admin/caisse/:id" layout={DashboardLayout} component={AddCaisse}></PrivateRoute>
        <PrivateRoute path="/admin/banque" exact layout={DashboardLayout} component={ListBanque}></PrivateRoute>
        <PrivateRoute path="/admin/ajoutbanque" layout={DashboardLayout} component={AddBanque}></PrivateRoute>
        <PrivateRoute path="/admin/banque/:id" layout={DashboardLayout} component={EditBanque}></PrivateRoute>
        <PrivateRoute path="/admin/cheque-emis" layout={DashboardLayout} component={ListChequeEmis}></PrivateRoute>
        <PrivateRoute path="/admin/pointage/correctionpointages" layout={DashboardLayout} component={PaiePointageMonth}></PrivateRoute>
        <PrivateRoute path="/admin/paie/CalculJourTravail" layout={DashboardLayout} component={CalculJourTravail}></PrivateRoute>

        <PrivateRoute path="/admin/listfacturer" layout={DashboardLayout} component={ListLigneFacturer}></PrivateRoute>
        <PrivateRoute path="/user/task"  layout={DashboardLayoutUser} component={ListTasks} ></PrivateRoute>
        <PrivateRoute path="/user/viewtask/:id" layout={DashboardLayoutUser} component={ViewTask} />
        <PrivateRoute path="/user/creetask" layout={DashboardLayoutUser} component={creeTask} />
        <AppRoute  path="/PageNotFound" layout={EmptyLayout} component={PageNotFound} />
        <PrivateRoute path="/user/supportManagement" layout={DashboardLayoutUser} component={supportDataGrid}></PrivateRoute>
        <PrivateRoute path="/user/satisfaction" layout={DashboardLayoutUser} component={ListSatisfaction}></PrivateRoute>
        <PrivateRoute path="/user/ajoutsatisfaction" layout={DashboardLayoutUser} component={AjouterSatisfaction}></PrivateRoute>
        <PrivateRoute path="/user/editsatisfaction/:id" layout={DashboardLayoutUser} component={AjouterSatisfaction}></PrivateRoute>

        <PrivateRoute path="/user/reunion"  layout={DashboardLayoutUser} component={ListReunionClient} ></PrivateRoute>
        <PrivateRoute path="/user/teleTravailcalendar"  layout={DashboardLayoutUser} component={TeleTravailCalendar} ></PrivateRoute>

        <PrivateRoute path="/admin/reunion"  layout={DashboardLayout} component={ListReunionClient} ></PrivateRoute>
        <PrivateRoute path="/admin/creereunion"  layout={DashboardLayout} component={ReunionClient} ></PrivateRoute>

        <PrivateRoute path="/user/creereunion"  layout={DashboardLayoutUser} component={ReunionClient} ></PrivateRoute>
        <PrivateRoute path="/user/updatereunion/:id"  layout={DashboardLayoutUser} component={ReunionClient} ></PrivateRoute>
        <PrivateRoute path="/admin/updatereunion/:id"  layout={DashboardLayout} component={ReunionClient} ></PrivateRoute>
        <PrivateRoute path="/admin/reportimpot"  layout={DashboardLayout} component={ReportImpot} ></PrivateRoute>

        <PrivateRoute path="/user" layout={DashboardLayoutUser} component={DashbordUser}></PrivateRoute>

        <PrivateRoute path="/admin/listbanques"  layout={DashboardLayout} component={ListBanques} ></PrivateRoute>
        <PrivateRoute path="/admin/exercice"  layout={DashboardLayout} component={Exercice} ></PrivateRoute>
        <PrivateRoute path="/admin/parametragepaie"  layout={DashboardLayout} component={ParametragePaie} ></PrivateRoute>
        <PrivateRoute path="/admin/MatricePaie"  layout={DashboardLayout} component={MatricePaie} ></PrivateRoute>
        <PrivateRoute path="/admin/Fichepaie"  layout={DashboardLayout} component={Fichepaie} ></PrivateRoute>

        <PrivateRoute path="/admin/AccueilPaie"  layout={DashboardLayout} component={AccueilPaie} ></PrivateRoute>
        <PrivateRoute path="/admin/ViewTaches"  layout={DashboardLayout} component={ViewTaches} ></PrivateRoute>
        <PrivateRoute path="/admin/declaration"  layout={DashboardLayout} component={DeclarationCnss} ></PrivateRoute>

        <AppRoute layout={EmptyLayout} component={PageNotFound} />


      </Switch>
    </BrowserRouter>
  );
}

export default App;/*ListTasks*/
