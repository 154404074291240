import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteTasById, getTasksByCode, getTasksById, getTasksByUsers } from "../../Services/Pointeuse/TasksApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { groupByProject } from "../../Utils/GroupeUtils";
import { reduceObject } from "../../Utils/ObjectOperation";
import { filterTasksByWord } from "../../Utils/SortObject";
import PieChartJsTaskStatus from "../Charts/CustumChartJs/PieChartJsTaskStatus";
import PieChartJsTaskType from "../Charts/CustumChartJs/PieChartJsTaskType";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import { t } from "i18next";
import { toast } from "react-toastify";
import { addTache, getDemandeClient, updateDemandeClient } from "../../Services/taches/TachesAPI";
import moment from "moment";

function ViewTaches({ codeclient }) {
    const [currentData, setcurrentData] = useState([]);
    const [allInitialData, setallInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setdata] = useState([]);
    const [users, setusers] = useState([]);
    const [projetsTask, setprojetsTask] = useState([]);
    const [size, setsize] = useState(14);
    const [isOpened, setIsOpened] = useState(false);
    const [tasks, settasks] = useState(null);
    const history = useHistory();
    const roleuser = localStorage.getItem('role_code')
    const api_url = process.env.REACT_APP_API_URL;
    const [date_debut, setdate_debut] = useState()
    const [date_fin, setdate_fin] = useState()
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        getDemandeClient().then((res) => {
            if (codeclient) {
                const filtreddata = res.data?.filter(item => item.code_client_externe === codeclient)
                setdata(filtreddata);

                setallInitialData(filtreddata);
                setprojetsTask(groupByProject(filtreddata));

                settasks(reduceObject(filtreddata));
            } else {
                setdata(res.data);

                setallInitialData(res.data);
                setprojetsTask(groupByProject(res.data));

                settasks(reduceObject(res.data));
            }

        })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));


    };



    /**
     * The function takes in a page of items and sets the currentData state to that page of items
     * @param pageOfItems - an array of items on the current page.
     */
    const onChangePage = (pageOfItems) => {
        // update state with new page of items
        setcurrentData(pageOfItems);
    };
    /**
     * It filters the data by the search term.
     * @param e - The event object that contains the target element.
     */
    const handleSearch = (string) => {
        const filtered = allInitialData.filter(
            (el) =>
                el?.societe?.toLowerCase().includes(string.toLowerCase()) ||
                el.titre?.toLowerCase().includes(string.toLowerCase()) ||
                el.demande?.toLowerCase().includes(string.toLowerCase())

        );
        if (filtered.length === 0) {
            setdata(allInitialData);


        } else {
            setdata(filtered);

            return filtered;
        }
    };
    /**
     * The function sets the size of the data to be retrieved from the API.
     * @param e - The event object that contains the data we want to use.
     */
    const handleChangeSize = (e) => {
        setsize(e.target.value);
        getData();
    };

    const handlePriority = (value) => setdata(value !== '' ? allInitialData.filter((el) => el.priority == value) : allInitialData);
    const handleCreate = (datedebut, datefin) => {

        const filteredData = allInitialData.filter((el) => {
            const createdAtDate = new Date(el.created_at.split("T")[0]); // Convertir la date de création en objet Date
            const startDate = new Date(datedebut); // Convertir date_debut en objet Date
            const endDate = new Date(datefin); // Convertir date_fin en objet Date

            // Filtrer les éléments dont la date de création est entre date_debut et date_fin
            return createdAtDate >= startDate && createdAtDate <= endDate;
        });

        setcurrentData(filteredData); // Mettre à jour les données filtrées
    };


    const navtotask = (el) => {

        // create to tache 
        const tache = {

            object: el.titre,
            description: el.demande || '',
            client_id: el.code_client_externe,
            projet_id: '',
            user_code_id: localStorage.getItem('id'),
            priority: data?.priority,
            type: '',
            commentaire: '',
            date_debut: null,
            date_fin: null,
            created_at: new Date(),
            etat: null,
            nmbr_jour: 0,
            nmbr_heure: 0,
            createdBy: localStorage.getItem('id'),
            code_generated: localStorage.getItem('code_generated'),
            canal: '',
            lien: '',
            assignedto: '',
            demande_id: el.id,
            file: el.file

        }
        addTache(tache).then((res) => {
            if (res?.status === 200) {
                const idtask = res.data.id
                const dataupdate = { etat: "converted", converted_at: moment(new Date()).format("YYYY-MM-DD"), converted_by: localStorage.getItem('id'), id: el.id }
                updateDemandeClient(dataupdate).then((resupdate) => {
                    if (resupdate.status === 200) {
                        toast.success(t("tache ajoutée "));
                        if (roleuser !== 'admin') {
                            const codeuser = localStorage.getItem('id')
                            if (codeuser !== el.user_code_id && codeuser !== el.assignedto) {
                                toast.warning('vous ne pouvez pas accéder a cette tache')
                            } else {
                                history.push("/user/viewtask/" + idtask);

                            }
                        } else {
                            history.push("/admin/viewtask/" + idtask);

                        }
                    }

                })

            } else {
                toast.error(t("un problème "));

            }
        });


    }
    const open_file = (Nomfile) => {
        const newTab = window.open(`${api_url}${Nomfile}`, '_blank');
        if (newTab) {
            // If the new tab was successfully opened, you can focus on it (optional)
            newTab.focus();
        } else {
            toast.error('Popup blocker prevented opening a new tab.');
        }
    };

    return (
        <>

            <div className="row">
                <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            {tasks && (
                                <div className="row mb-0 pb-0 ">
                                    <div className="col-md-6 col-lg-6 text-center py-5">
                                        <span className="badge badge-primary" style={{ padding: '20px' }}>{tasks?.converted || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("convertie")}</h5>
                                    </div>
                                    <div className="col-md-6 col-lg-6 text-center py-5">
                                        <span className="badge badge-warning" style={{ padding: '20px' }}>{tasks?.crée || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("Crée")}</h5>
                                    </div>

                                </div>

                            )}


                        </div>
                    </div>
                </div>
            </div>


            <div className="row">

                <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header  border-0">
                            <h4 className="card-title">{t("List demandes clients")}</h4>
                        </div>

                        <div className="card-body">


                            {loading && <CustomSyncLoader></CustomSyncLoader>}
                            {!loading && error && <ErrorCustum></ErrorCustum>}
                            {!loading && !error && data && (
                                <div className="table-responsive">
                                    <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        {codeclient ? (
                                            <div className="row">
                                                <div className=" col-sm-12 col-md-12 col-lg-12">
                                                    <div className="dataTables_length" id="hr-table_length">
                                                        <label>
                                                            {t("Afficher")}{" "}
                                                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                                                <option value={8}>8</option>
                                                                <option value={20}>20</option>
                                                                <option value={40}>40</option>
                                                                <option value={60}>60</option>
                                                                <option value={100}>100</option>
                                                            </select>{" "}

                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-6 mb-3" >
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text " >
                                                                <i className="feather feather-calendar" /> {t('Date Debut')}
                                                            </div>
                                                        </div>
                                                        <input
                                                            onChange={(e) => {
                                                                setdate_debut(e.target.value);
                                                                handleCreate(e.target.value, date_fin); // Appeler handleCreate après avoir mis à jour date_debut
                                                            }}
                                                            className="form-control fc-datepicker"
                                                            placeholder="DD-MM-YYYY"
                                                            type="date"
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-sm-12  col-md-12 col-lg-6 mb-3">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text " >
                                                                <i className="feather feather-calendar" /> {t('Date Fin')}
                                                            </div>
                                                        </div>
                                                        <input
                                                            onChange={(e) => {
                                                                setdate_fin(e.target.value);
                                                                handleCreate(date_debut, e.target.value); // Appeler handleCreate après avoir mis à jour date_fin
                                                            }}
                                                            className="form-control fc-datepicker"
                                                            placeholder="DD-MM-YYYY"
                                                            type="date"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-4">
                                                    <div className="form-group">

                                                        <select
                                                            name="attendance"
                                                            className="form-control custom-select select2"
                                                            data-placeholder={t("Choisir priorité")}
                                                            onChange={(e) => handlePriority(e.target.value)}
                                                        >
                                                            <option value="" label={t("Choisir priorité")} />
                                                            <option value={0}>0-Aucune priorité</option>
                                                            <option value={1}>1-Priorité la plus élevée</option>
                                                            <option value={2}>2-Priorité élevée</option>
                                                            <option value={3}>3-Priorité moyenne</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-lg-2 col-md-12">
                                                    <Link to="#" className="btn btn-primary btn-block" onClick={getData}>
                                                        {t("Réinitialiser")}
                                                    </Link>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-5">
                                                    <div id="hr-table_filter" className="dataTables_filter">
                                                        <label className="mr-2">
                                                            <input
                                                                type="search"
                                                                className="form-control"
                                                                placeholder={t("Recherche...")}
                                                                aria-controls="hr-table"
                                                                onChange={(e) => handleSearch(e.target.value)}
                                                            />
                                                        </label>

                                                        <ExportCsv data={data} name={"List_tâches"} />
                                                        <ExportPdf data={data} name={"List_tâches"} columns={["object", "description", "etat", "priority", "date_fin"]} />
                                                        <CopieContent />
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                            <><div className="row">
                                                <div className=" col-sm-12 col-md-12 col-lg-2">
                                                    <div className="dataTables_length" id="hr-table_length">
                                                        <label>
                                                            {t("Afficher")}{" "}
                                                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                                                <option value={8}>8</option>
                                                                <option value={20}>20</option>
                                                                <option value={40}>40</option>
                                                                <option value={60}>60</option>
                                                                <option value={100}>100</option>
                                                            </select>{" "}

                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-3">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text ">
                                                                <i className="feather feather-calendar" /> {t('Date Debut')}
                                                            </div>
                                                        </div>
                                                        <input
                                                            onChange={(e) => {
                                                                setdate_debut(e.target.value);
                                                                handleCreate(e.target.value, date_fin); // Appeler handleCreate après avoir mis à jour date_debut
                                                            }}
                                                            className="form-control fc-datepicker"
                                                            placeholder="DD-MM-YYYY"
                                                            type="date" />
                                                    </div>

                                                </div>
                                                <div className="col-sm-12  col-md-12 col-lg-3">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text ">
                                                                <i className="feather feather-calendar" /> {t('Date Fin')}
                                                            </div>
                                                        </div>
                                                        <input
                                                            onChange={(e) => {
                                                                setdate_fin(e.target.value);
                                                                handleCreate(date_debut, e.target.value); // Appeler handleCreate après avoir mis à jour date_fin
                                                            }}
                                                            className="form-control fc-datepicker"
                                                            placeholder="DD-MM-YYYY"
                                                            type="date" />
                                                    </div>

                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-2">
                                                    <div className="form-group">

                                                        <select
                                                            name="attendance"
                                                            className="form-control custom-select select2"
                                                            data-placeholder={t("Choisir priorité")}
                                                            onChange={(e) => handlePriority(e.target.value)}
                                                        >
                                                            <option value="" label={t("Choisir priorité")} />
                                                            <option value={0}>0-Aucune priorité</option>
                                                            <option value={1}>1-Priorité la plus élevée</option>
                                                            <option value={2}>2-Priorité élevée</option>
                                                            <option value={3}>3-Priorité moyenne</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-lg-2 col-md-12">
                                                    <Link to="#" className="btn btn-primary btn-block" onClick={getData}>
                                                        {t("Réinitialiser")}
                                                    </Link>
                                                </div>
                                            </div><div className="row" >

                                                    <div className=" col-sm-12 col-md-12 col-lg-12">
                                                        <div id="hr-table_filter" className="dataTables_filter">
                                                            <label className="mr-2">
                                                                <input
                                                                    type="search"
                                                                    className="form-control"
                                                                    placeholder={t("Recherche...")}
                                                                    aria-controls="hr-table"
                                                                    onChange={(e) => handleSearch(e.target.value)} />
                                                            </label>

                                                            <ExportCsv data={data} name={"List_tâches"} />
                                                            <ExportPdf data={data} name={"List_tâches"} columns={["object", "description", "etat", "priority", "date_fin"]} />
                                                            <CopieContent />
                                                        </div>
                                                    </div>
                                                </div></>}
                                        <div className="row">
                                            <div className="col-12">
                                                <table className="table  table-vcenter text-nowrap table-bordered border-bottom" id="task-list">
                                                    <thead>
                                                        <tr>

                                                            <th className="border-bottom-0">{t("client")}</th>
                                                            <th className="border-bottom-0">{t("titre")}</th>
                                                            <th className="border-bottom-0">{t("Priorité")}</th>
                                                            <th className="border-bottom-0">{t("demande")}</th>
                                                            <th className="border-bottom-0">{t("Date")}</th>
                                                            <th className="border-bottom-0">{t("fichier")}</th>
                                                            <th className="border-bottom-0">{t("Statut")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentData.map((el, idx) => (
                                                            <tr key={idx}>
                                                                <td >
                                                                    {el.societe}
                                                                </td>
                                                                <td>

                                                                    {el.titre}

                                                                </td>
                                                                <td>
                                                                    {el.priority == 0 && <span className="badge badge-success-light">{el.priority}</span>}
                                                                    {el.priority == 1 && <span className="badge badge-warning-light">{el.priority}</span>}
                                                                    {el.priority == 2 && <span className="badge badge-orange-light">{el.priority}</span>}
                                                                    {el.priority == 3 && <span className="badge badge-danger-light">{el.priority}</span>}
                                                                </td>
                                                                <td>
                                                                    {el.demande}
                                                                </td>
                                                                <td>
                                                                    {moment(el.created_at).format("YYYY-MM-DD")}
                                                                </td>
                                                                {el.file ? (<td onClick={() => open_file(el.file)}>{t('voir fichier')}</td>) : <td></td>}
                                                                <td>
                                                                    {el.etat === 'converted' && (
                                                                        <div className="d-flex">
                                                                            <span className="badge badge-primary">convertie</span>

                                                                        </div>
                                                                    )}
                                                                    {el.etat === 'crée' && (
                                                                        <div className="d-flex">
                                                                            <span className="badge badge-warning">{el.etat}</span>

                                                                        </div>
                                                                    )}

                                                                </td>
                                                                <td>
                                                                    {el.etat !== 'converted' &&
                                                                        <div className="d-flex" onClick={() => navtotask(el)}>

                                                                            <p className="action-btns1">
                                                                                <i
                                                                                    className="feather feather-edit-2  text-success"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title="Edit task"
                                                                                />

                                                                            </p> {t('convertir a une tache')}

                                                                        </div>}

                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                                                    {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default ViewTaches; 
