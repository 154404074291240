import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getFacturesPartiellementPayees } from '../../Services/dashboardService';

export default function CardFacturePartiellementPayee() {
  const { t } = useTranslation();
  const [factures, setFactures] = useState([]);
  const [totaux, setTotaux] = useState({ netAPayer: 0, montantEncaisse: 0, qte: 0 });



  const getLastMonthStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setHours(12, 0, 0, 0);
    return date.toISOString().split('T')[0];
  };
  const [dateDebut, setDateDebut] = useState(getLastMonthStartDate);

  // Function to get today's date
  const getTodayDate = () => {
    const date = new Date();
    date.setHours(12, 0, 0, 0);
    return date.toISOString().split('T')[0];
  };

  const [dateFin, setDateFin] = useState(getTodayDate);

  useEffect(() => {

    fetchData();
 
  }, []);

  const fetchData = async () => {
    const data = await getFacturesPartiellementPayees(dateDebut, dateFin);
    setFactures(data.data.factures);
  };


  useEffect(() => {
    const totalNetAPayer = factures.reduce((acc, f) => acc + f.net_a_payer, 0);
    const totalMontantEncaisse = factures.reduce((acc, f) => acc + f.total_montant_encaisse, 0);
    setTotaux({ netAPayer: totalNetAPayer, montantEncaisse: totalMontantEncaisse, qte: factures.length });
  }, [factures]);

  const onChangeDate = () => {
    fetchData();
  };

  return (
    <div className="p-4 bg-white shadow-lg rounded-xl">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">{t("Factures Partiellement Payées")}</h2>
      <div className='row mb-3'>
        <div className=' col-4'>
          <label className="form-label">{t("Date Début")}</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="feather feather-calendar" />
              </div>
            </div>
            <input className="form-control floating" type="date"
              value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
          </div>
        </div>
        <div className='col-4'>
          <label className="form-label">{t("Date Fin")}</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="feather feather-calendar" />
              </div>
            </div>
            <input className="form-control floating" type="date"
              value={dateFin} onChange={(e) => setDateFin(e.target.value)} />

          </div>
        </div>
        <div className=" col-3 mt-5">
          <button className="bg-green-100 text-white px-4 py-2 rounded-lg hover:bg-green-100"
            onClick={onChangeDate}>
            🔍
          </button>

        </div>
      </div>





      <div className="mt-4 p-5 bg-gray-100 rounded-lg shadow-md mb-6">
        <p className="text-lg font-semibold text-gray-700"><strong>{t("Total Net à Payer")}:</strong> {totaux.netAPayer.toFixed(2)}</p>
        <p className="text-lg font-semibold text-gray-700"><strong>{t("Total Montant Encaissé")}:</strong> {totaux.montantEncaisse.toFixed(2)}</p>
        <p className="text-lg font-semibold text-gray-700"><strong>{t("Quantité Factures")}:</strong> {totaux.qte}</p>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={factures}>
          <XAxis dataKey="facture" stroke="#4B5563" />
          <YAxis stroke="#4B5563" />
          <Tooltip contentStyle={{ backgroundColor: '#F9FAFB', borderRadius: '8px' }} />
          <Legend />
          <Bar dataKey="net_a_payer" fill="#1E3A8A" radius={[4, 4, 0, 0]} name={t("Total Net à Payer")} />
          <Bar dataKey="total_montant_encaisse" fill="#6366F1" radius={[4, 4, 0, 0]} name={t("Total Montant Encaissé")} />
        </BarChart>
      </ResponsiveContainer>

     
    </div>
  );
}