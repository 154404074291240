import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { copiePointage, correctionPointage, getAllPointageHistory, getcorrectionPointage } from "../../Services/Pointeuse/PointageApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUsersActifByCode, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import SingleMonthPointage from "./PaieSingleMonthPointage";
import _ from "lodash";
import swal from "sweetalert";
import { toast } from "react-toastify";
import moment from "moment";
import { showDay } from "../../Utils/PointageMonthUtils";
import ValidationPaie from "./ValidationPaie";
import { getExercice, getjour_ferire } from "../../Services/Pointeuse/EntrepriseApi";



function PaiePointageMonth() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const list_date = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
  const [listcopiepointage, setlistcopiepointage] = useState()
  const [list_correction, setlist_correction] = useState([])
  const [list_id_remove, setlist_id_remove] = useState([])
  const [total, setTotal] = useState()
  const [showModal, setshowModal] = useState(false)
  const listmois = ['Janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre']

  const [userlist, setUser] = useState([])
  const [check_user, setcheck_user] = useState(false)
  const [user_selected, setuser_selected] = useState([])

  const [hide, sethide] = useState(true)
  const [jour_ferie, setjour_ferie] = useState([]);

  const [dateP, setdateP] = useState()
  const [exercice, setexercice] = useState()
  const [exercice_selectionne, setexercice_selectionne] = useState()
  const [mois_selected, setmois_selected] = useState()

  const [dataPointages, setDataPointages] = useState(null);
  useEffect(() => {
    getjour_ferire().then((res) => {
      if (res?.data.data) {

        const list = res?.data.data.filter(item => item.type === 'CP');


        let new_list = [...list];

        list.forEach(element => {
          if (element.nbre_jours > 1) {
            for (let i = 1; i < element.nbre_jours; i++) {
              const copie = { ...element };
              let [day, month] = copie.date.split('-').map(Number);

              day += i;
              if (day < 10) {
                day = '0' + day;
              }
              copie.date = `${day}-${month < 10 ? '0' + month : month}`;
              new_list.push(copie);
            }
          }
        });



        setjour_ferie(new_list.map(item => item.date))
      }


    });
  }, [])
  useEffect(() => {
    getExercice().then((res) => {

      setexercice(res.data);
      setexercice_selectionne(res.data[0].id)

      var dateObj = new Date();
      var result = dateObj.toISOString().split("T")[0];
      var month = result.split("-")[1];
      setmois_selected(parseInt(month) - 1)
      var year = res.data[0].code_exercice;
      var newdate = year + "-" + month;
      setdateP(newdate)
    })
  }, [])
  useEffect(() => {
    if (dateP) {
      const y = dateP.split('-')[0];
      const m = dateP.split('-')[1]
      const data = { year: y, month: m, code_generated: localStorage.getItem('code_generated') }

      copiePointage(data).then((res) => {
        // grouped by user code 
        const groupedByUser = _.groupBy(res.data.data, "user_code_id");
        setlistcopiepointage(groupedByUser)
      });
    }

  }, [dateP]);
  const getExercicedate = (date) => {

    const find_exercice = exercice?.find(item => item.code_exercice === date.split('-')[0])
    return find_exercice;

  }
  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getData();
  }, []);



  /**
   * The function takes in a date and sets the date state to that date
   * @param date - The date that the user has selected.
   */
  const handleChangeDate = (type, value) => {
    let date;
    if (type === 'mois') {
      let mois = parseInt(value) + 1;
      mois = mois < 10 ? `0${mois}` : mois;
      date = `${dateP.split('-')[0]}-${mois}`;
      setmois_selected(value)
      setdateP(date);
    }
    else {
      const find_ex = exercice.find(item => item.id === parseInt(value));

      if (!find_ex) {
        // Handle case where exercise with the specified ID is not found
        console.error(`Exercise with ID ${value} not found.`);
        return; // Return early to avoid further execution
      }

      date = `${find_ex.code_exercice}-${dateP.split('-')[1]}`;

      if (find_ex.active === 0) {
        swal({
          title: t("Ëtes-vous sûr ?"),
          text: t("Voulez-vous sélectionner un exercice qui n'est pas actif "),
          icon: "warning",
          buttons: {
            cancel: t("Cancel"),
            confirm: {
              text: t("OK"),
              value: true,
            },
          },
          dangerMode: true,
        }).then((res) => {
          if (res) {
            setexercice_selectionne(find_ex.id);
            setdateP(date);
          }
        });
      } else {
        setexercice_selectionne(find_ex.id);
        setdateP(date);
      }
    }
  };


  useEffect(() => {
    getAllPointageHistory().then((res) => {
      setDataPointages(res.data);
    });
  }, []);


  useEffect(() => {
    if (dateP) {
      getcorrectionPointage(dateP).then((res) => {

        if (res.success === 0) {
          toast.error(res.data.message)

        } else {
          const datacorrect = res.data.map(element => {
            return {
              id: element.id,

              jr: element.day.toString().length === 1 ? `0${element.day}` : element.day.toString(),
              user: parseInt(element.user),
              obj: element.motif,
              duree: element.duree,
              type_correction: element.type_correction
            }
          });

          setlist_correction(datacorrect)

          //setTotal(newt)

        }
      });
    }



  }, [dateP])

  useEffect(() => {
    if (data && listcopiepointage) {
      const groupedByUser = _.groupBy(list_correction, "user");
      let newTotal = []

      data.forEach((item) => {

        const totalDays = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res !== 'AB' && determineEtat(curr, item).res !== 'weekend' && determineEtat(curr, item).res !== 'PM1' && determineEtat(curr, item).res !== 'PM2') {
            return acc + determineEtat(curr, item).val;
          }
          return acc;
        }, 0);
        const totalconge = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res === 'C') {
            return acc + 1;
          }
          return acc;
        }, 0);
        const totalP = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res === 'TR') {
            return acc + 1;
          }
          return acc;
        }, 0);
        const totalCR = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res === 'CR' || determineEtat(curr, item).res === 'AB/CR') {
            return acc + determineEtat(curr, item).val;
          }
          return acc;
        }, 0);
        const totalJF = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res === 'jf') {
            return acc + 1;
          }
          return acc;
        }, 0);
        const totalAB = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res === 'AB' || determineEtat(curr, item).res === 'AB/CR') {
            return acc + determineEtat(curr, item).val;
          }
          return acc;
        }, 0);
        const totalNP = list_date?.reduce((acc, curr) => {
          if (determineEtat(curr, item).res === 'PM2' || determineEtat(curr, item).res === 'PM1') {
            return acc + determineEtat(curr, item).val;
          }
          return acc;
        }, 0);
        const totalcorrection = totalDays
        newTotal.push({ user: item.id, total: totalcorrection, totalconge: totalconge, totalP: totalP, totalCR: totalCR, totalJF: totalJF, total_Ab: totalAB, totalNP: totalNP });
      });
      setTotal(newTotal)



    }
  }, [data, listcopiepointage, list_correction, dateP]);
  useEffect(() => {
    const listusers = data.map(objet => ({ nom: objet.nom, prenom: objet.prenom, id: objet.id }));
    setUser(listusers)
  }, [data]);
  const savecorrectionPointage = () => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment enregistrer cette  correction!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        const res = await save();
        if (res.status === 201) {
          toast.success(res.data.message)
        } else {
          toast.error(res.data.message)

        }
      }
    });


  }
  const save = async () => {
    const list_saved = list_correction.map(element => {
      return {
        id: element.id,
        generated_code: localStorage.getItem('code_generated'),
        date: moment(new Date(`${dateP}-${element.jr}`)).format("YYYY-MM-DD"),
        user: element.user,
        motif: element.obj,
        duree: element.duree,
        type_correction: element.type_correction
      }
    });
    const list_totaux = total.map(element => {
      return {
        user_code: element.user,
        total_P: element.totalP,
        total_jr_ferire: element.totalJF,
        total_conge: element.totalconge,
        total_coriger: element.totalCR,
        total: element.totalP + element.totalJF + element.totalconge + element.totalCR,
        total_Ab: element.total_Ab + element.totalNP,
        year_mois: dateP
      }

    });

    const data = { list_saved: list_saved, list_id_remove: list_id_remove, list_totaux: list_totaux }
    const res = await correctionPointage(data);
    return res;
  }
  const determineEtat = (item, user) => {
    const d = listcopiepointage[user.id]
    let val = 1;
    const travail = d?.find((ligne) => ligne.pointage_entree?.split("-")[2].split("T")[0] === item)&& d?.find((ligne) => ligne.pointage_sortie?.split("-")[2].split("T")[0] === item) ;
    const PM1 = !d?.find((ligne) => ligne.pointage_entree?.split("-")[2].split("T")[0] === item) ;

    const PM2 = d?.find((ligne) => ligne.pointage_entree?.split("-")[2].split("T")[0] === item) && !d?.find((ligne) => ligne.pointage_sortie?.split("-")[2].split("T")[0] === item);
    const conge = d?.find((ligne) => ligne.conge_date?.split("-")[2].split("T")[0] === item) && d?.find((ligne) => ligne.conge_date?.split("-")[2].split("T")[0] === item);

    const m = dateP.split('-')[1]
    const jr_ferie = jour_ferie?.find((itemjf) => m === itemjf?.split("-")[1] && itemjf?.split("-")[0] === item);

    if (conge && conge.conge_nbr_jour > 1) {
      var copieconge = { ...conge };

      for (var i = 0; i < conge.conge_nbr_jour - 1; i++) {
        var congeDate = new Date(conge.conge_date);
        congeDate.setDate(congeDate.getDate() + i + 1);
        var copieconge = { ...conge };
        copieconge.conge_date = congeDate.toISOString();
        copieconge.conge_nbr_jour = 0;
        d.push(copieconge);
      }
    }

    const weekend = showDay(dateP, item);
    const corrected = list_correction.find((CR) => CR.jr === item && CR.user === user.id);
    val = list_correction.find((CR) => CR.jr === item && CR.user === user.id)?.duree === '0.5' ? 0.5 : val;

    let res = jr_ferie ? "jf" : corrected ? "CR" : weekend ? "weekend" : travail ? "TR" : conge ? "C": PM2?'PM2': PM1 ? 'PM1': "AB";
    res = (res === 'CR' && val === 0.5) ? 'AB/CR' : res
    return { res: res, val: val };
  }
  const handleMouseLeave = () => {
    //swal("Enregistrer les modification  !");


  }
  const handleverif = () => {
    swal({
      title: t("Verifier?"),
      text: t("vous voulez enregistrer les modification !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then(async (res) => {
      if (res) {
        const result = await save();
        if (result.status === 201) {
          toast.success(result.data.message)
          setcheck_user(true)

        } else {
          toast.error(t("un probleme !!"))

        }
      }
    });


  }
  const selectionall = () => {

    const alldata = data.map(item => item.id)

    setuser_selected(alldata)
  }
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Validation du  Pointage  ")}{exercice_selectionne?.code_exercice}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="page-rightheader ml-md-auto">
            <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
              <div className="btn-list">
                <button className="btn btn-primary mr-3" onClick={() => setshowModal(true)}>
                  {t("Afficher fiche paie")}
                </button>
                <button className="btn btn-primary mr-3" onClick={handleverif}>
                  {t("préparation du paie")}
                </button>
                <ButtonHeaderComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">{t("Choisir l'exercice")}:</label>
                    <div className="input-group" >
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="feather feather-calendar" />
                        </div>
                      </div>

                      <select className="form-control" value={exercice_selectionne} onChange={(e) => handleChangeDate('exercice', e.target.value)}>
                        {exercice?.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.code_exercice}
                          </option>
                        ))}
                      </select>



                    </div>


                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">{t("Choisir le mois")}:</label>
                    <div className="input-group" >
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="feather feather-calendar" />
                        </div>
                      </div>


                      <select className="form-control" value={mois_selected} onChange={(e) => handleChangeDate('mois', e.target.value)}>
                        {listmois.map((item, index) => (
                          <option value={index} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>


                    </div>

                    {/* <div>
                  <li>
                      <a href="#tab13" data-toggle="tab">
                        {t("Pointage par mois Par Collaborateur")}
                      </a>
                    </li>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body" onMouseLeave={handleMouseLeave} >
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge present_tag mr-2">
                    <i className="feather feather-check-circle " /> {t("Présent")}
                  </span>
                  <span className="badge coriger_tag mr-2" style={{ color: 'black' }}>
                    <i className="feather feather-check-circle " /> {t("Corrigée")}
                  </span>
                  <span className="badge Ab_tag mr-2">
                    <i className="feather feather-x-circle " /> {t("Absent")}
                  </span>

                  <span className="badge SNP_tag mr-2">
                    <i className="feather feather-x-circle " /> {t("Sortie non pointée")}
                  </span>

                  <span className="badge ENP_tag mr-2" >
                    <i className="feather feather-x-circle " />  {t("Entrée non pointée")}
                  </span>

                  <span className="badge JF_tag mr-2" >
                    <i className="fa fa-adjust  " /> {t("Jour férié")}
                  </span>

                  <span className="badge Cg_tag  mr-2">
                    <i className="fa fa-adjust " /> {t("Congé")}
                  </span>

                  <span className="badge Weekend_tag mr-2">
                    <i className="fa fa-calendar " /> {t("Weekend")}
                  </span>
                  <span className="badge Demi_tag mr-2">
                    <i className="fa fa-calendar " /> {t("Demi_journée")}
                  </span>
                </div>

              </div>
              {check_user && (user_selected.length === 0 ? (<p className='pointer link' onClick={selectionall}>{t('Sélection tout')}</p>) : <p className='pointer link' onClick={() => setuser_selected([])}>{t('Désélectionné')}</p>)}

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && listcopiepointage && (
                <div className="table-responsive hr-attlist">
                  <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                    <thead>
                      <tr>
                        <th className="border-bottom-0">{t("nom/prénom")} <button className=" btn   float-right" style={{ pointer: 'cursor' }} onClick={() => sethide(!hide)}>
                          {hide ? '>' : '<'}
                        </button></th>
                        {!hide && list_date.map(item =>
                          <th>{item}</th>
                        )}
                        <th className="border-bottom-0">Total corrigé</th>
                        <th className="border-bottom-0">Total jour férié</th>
                        <th className="border-bottom-0">Total P</th>
                        <th className="border-bottom-0">Total congé</th>

                        <th className="border-bottom-0">Total</th>


                      </tr>
                    </thead>
                    {listcopiepointage && (
                      <tbody>
                        {data.map((el, idx) => (
                          <SingleMonthPointage user={el}
                            data={listcopiepointage[el.id] || []}
                            list_correction={list_correction}
                            setlist_correction={setlist_correction}
                            setlist_id_remove={setlist_id_remove}
                            list_id_remove={list_id_remove}
                            determineEtat={determineEtat}
                            total={total}
                            setTotal={setTotal}
                            hide={hide}
                            t={t}
                            check_user={check_user}
                            setuser_selected={setuser_selected}
                            user_selected={user_selected} />
                        ))}
                      </tbody>
                    )}

                    <tfoot>
                      <tr>
                        <th className="border-bottom-0">{t("nom/prénom")}</th>
                        {!hide && list_date.map(item =>
                          <th>{item}</th>
                        )}
                        <th className="border-bottom-0">Total corrigé</th>
                        <th className="border-bottom-0">Total jour férié</th>
                        <th className="border-bottom-0">Total P</th>
                        <th className="border-bottom-0">Total congé</th>

                        <th className="border-bottom-0">Total</th>
                      </tr>
                    </tfoot>
                  </table>

                </div>
              )}
              {check_user ? (
                <>

                  <Link to={{ pathname: `/admin/MatricePaie/`, state: { user_selected: user_selected, date: dateP, exercice: exercice_selectionne } }} className="btn btn-primary float-right ml-2" >
                    {t("Fiche de paie")}
                  </Link>
                  <button onClick={() => { setcheck_user(false); setuser_selected([]) }} className="btn btn-primary float-right">
                    {t("Annuler")}
                  </button>
                </>
              ) :
                <button onClick={savecorrectionPointage} className="btn btn-primary float-right" >
                  {t("Enregistrer")}
                </button>}

              <ValidationPaie
                show={showModal}
                setshow={setshowModal}
                userlist={userlist}
                total={total}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaiePointageMonth;
