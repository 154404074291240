import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { updateEntetChequeById, getEntetChequeById, getChequesByCode, getEntetChequesByCode } from "../../Services/Pointeuse/ChequeApi";
import { useAlert } from "react-alert";
import { use } from "react";
import { getbanques } from "../../Services/banque";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";



function ViewCarnetModal(props) {
  const history = useHistory();
  const alert = useAlert();
  const myRefHide = useRef(null);

  const [Carnet, setCarnet] = useState({
    id: '',
    date: '',
    num1: '',
    nombre: '',
    banque: '',
    numFinal: '',

  });
  useEffect(() => {
    const num1 = parseInt(Carnet.num1, 10) || 0;
    const nombre = parseInt(Carnet.nombre, 10) || 0;
    const numFinal = num1 + nombre - 1;
  
    setCarnet((prev) => ({ ...prev, numFinal: numFinal > 0 ? numFinal : "" }));
  }, [Carnet.num1, Carnet.nombre]);
  const [banquelist, setBanquelist] = useState([]);
  useEffect(() => {
    getData();
    // props.setisEditing(false);
  }, [props]);
  useEffect(() => {
    fetchBanques();

  }, [])

  const getData = () => {
    if (!props.idCarnet) return;
    getEntetChequeById(props.idCarnet).then((res) => {

      setCarnet({
        id: res.data.id || "",
        date: res.data.date.split(' ')[0] || "",
        num1: res.data.premier_cheque || "",
        nombre: res.data.nombre || "",
        banque: res.data.banque || "",
        numFinal: res.data.dernier_cheque || ""

      });

    })
      .catch((err) => alert.error("Erreur lors du chargement du carnet."));
  };
  // Vérification que tous les champs sont remplis
  const fetchBanques = () => {
    getbanques()
      .then((res) => {
        if (res.status === 200)
          setBanquelist(res?.data)
      }
      )
      .catch((err) => alert.error("Erreur lors du chargement des banques."));
  };

  const handleSubmitCarnet = () => {
    updateEntetChequeById(Carnet).then((rep) => {
      if (rep.status === 200) {
        alert.success("updated succesfuly")
        props.getlist()
        props.setshowModal(false)


      } else {
        alert.error("Erreur lors de la mise à jour du carnet.");
      }

    })
      .catch((err) => alert.error("Erreur lors de la mise à jour du carnet."));




    // Envoi des données à l'API

  };

  return (
    <Modal show={props.showModal} onHide={() => props.setshowModal(false)} >
      <Modal.Header closeButton onClick={() => props.setshowModal(false)}>
        <Modal.Title>         {props.idCarnet ? ' Editer Carnet Cheque' : 'Ajouter Carnet Cheque'}
        </Modal.Title>
      </Modal.Header>


      <Modal.Body>
        <form>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              placeholder="..."
              required
              value={Carnet.date}
              onChange={(e) => setCarnet(prev => ({ ...prev, date: e.target.value }))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              num1<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="..."
              required
              value={Carnet.num1}
              onChange={(e) => setCarnet(prev => ({ ...prev, num1: e.target.value }))}
            />
          </div>



          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              nombre<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="..."
              required
              value={Carnet.nombre}
              onChange={(e) => setCarnet(prev => ({ ...prev, nombre: e.target.value }))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputPassword1">
              banque<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              value={Carnet.banque}
              onChange={(e) => setCarnet(prev => ({ ...prev, banque: e.target.value }))}
            >
              
              {banquelist && banquelist.length !== 0 && banquelist.map((item, index) => (

                <option key={index} value={item.id}>
                  {item.banque}
                </option>
              ))}
            </select>
          </div>


          <div className="form-group">
            <label htmlFor="exampleInputPassword1">
              numFinal<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="..."
              required
              value={Carnet.numFinal}
              onChange={(e) => setCarnet(prev => ({ ...prev, numFinal: e.target.value }))}
            />
          </div>

          <div className="form-check"></div>
        </form>
      </Modal.Body>


      <Modal.Footer>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => props.setshowModal(false)}>
          Annuler
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmitCarnet}>
          Enregistrer
        </button>
      </Modal.Footer>

    </Modal>
  );
}

export default ViewCarnetModal;
