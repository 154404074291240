import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL_V1 || 'http://localhost:3001/api/v1/';

export const getFacturesPartiellementPayees = async (exercice) => {
  try {
    const response = await axios.get(`${API_URL}ComptableDashboard/factures-partiellement-payees/${exercice}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


export const getFacturesPayees = async (exercice) => {
  try {
    const response = await axios.get(`${API_URL}ComptableDashboard/factures-payees/${exercice}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


export const getFacturesNonPayees = async (exercice) => {
  try {
    const response = await axios.get(`${API_URL}ComptableDashboard/factures-non-payees/${exercice}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
