import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useAlert } from "react-alert";
import { getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import { t } from "i18next";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function AddCarnetCheque() {
    const history = useHistory();
    const alert = useAlert();
    const [dataFournisseur, setDataFournisseur] = useState([]);
    const [fournisseurFullName, setFournisseurFullName] = useState("");
    const [fournisseurCode, setFournisseurCode] = useState("");

    const [nom, setnom] = useState(null);
    const [montant, setmontant] = useState(null);
    const [numero, setnumero] = useState(null);
    const [date, setdate] = useState(null);
    const [files, setFiles] = useState(null);

    const handleSubmitEncaissement = (e) => {
        e.preventDefault();

       /* if (files) {
            ajouterCheque(date, fournisseurCode, montant, numero, files[0].file).then((res) => {
                history.push("/admin/cheque");
            });
        } else {
            ajouterCheque(date, fournisseurCode, montant, numero, null).then((res) => {
                history.push("/admin/cheque");
            });
        }*/
    };

    const handleChangeFournisseur = (event) => {
        setFournisseurCode(event.value);
        setFournisseurFullName(event.label);
    };

    useEffect(() => {
        getFacturationClientByCode().then((res) => {
            const result = res.data;
            const options = result.map((d) => ({
                value: d.code,
                label: d.nom + " " + d.prenom,
                secondValue: d.id,
            }));
            setDataFournisseur(options);
        });
    }, []);

    return (
        <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
            <div className="card">
                <div className="card-header  border-0">
                    <h4 className="card-title">{t("Ajouter Chèque")}</h4>
                </div>
                <div className="card-body">
                    <div className="container-fluid">
                        <div class="row">
                            {/* <div class="col-sm-12 col-lg-6">
                <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
              </div> */}
                            <div class="col-sm-12 col-lg-12">
                                <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <div className="form-group">
                                                <label>{t("Nom")}</label>
                                                <Select onChange={handleChangeFournisseur} options={dataFournisseur} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">{t("Montant")}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="ex : 100.100"
                                                    onChange={(e) => setmontant(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">{t("Numéro")}</label>
                                                <input type="text" className="form-control" required onChange={(e) => setnumero(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">{t("Date")}</label>
                                                <input type="date" className="form-control" required onChange={(e) => setdate(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {files && <img src={URL.createObjectURL(files[0].file)} alt=""></img>} */}
                                    <FilePond
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={true}
                                        maxFiles={10}
                                        name="files"
                                        labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
                                    />
                                    <button type="submit" className="btn btn-primary">
                                        {t("Ajouter")}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

