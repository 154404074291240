import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { filterCaisse, filterFactureByWord } from "../../Utils/SortObject";
import swal from "sweetalert";
import { deleteCaisseById, getCaisseByCode } from "../../Services/Pointeuse/CaisseApi";
import { Link } from "react-router-dom";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ExportCsv from "../Exports/ExportCsv";
import CopieContent from "../Exports/CopieContent";
import Pagination from "../Others/Pagination";
import { sumObjectCurrentMonth, sumObjectif, sumObjectValue } from "../../Utils/ObjectifUtils";
import moment from "moment";
import { error_message } from "../alerte/AlerteMessage";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
function ListCaisse() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  let history = useHistory();
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [actionId, setActionId] = useState(false);
  const [factureCode, setfactureCode] = useState(null);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(40);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [allEncaiss, setallEncaiss] = useState(null);
  const [resultEnc, setresultEnc] = useState(null);
  const getLastMonthStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1); // Go to last month
    date.setHours(12, 0, 0, 0); // Ensure date is accurate to today's date by setting noon
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };
  
  // Function to get today's date
  const getTodayDate = () => {
    const date = new Date();
    date.setHours(12, 0, 0, 0); // Ensure date is accurate to today's date by setting noon
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };
  
  const [startDate, setStartDate] = useState(getLastMonthStartDate);
  const [endDate, setEndDate] = useState(getTodayDate);
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getCaisseByCode(startDate,endDate)
      .then((res) => {
        setallInitialData(res.data);
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterCaisse(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const filterByDate = (value) => {
    const filterd = allInitialData.filter((el) => {
      if (el.date) {
        return el.date == value;
      }
    });

    if (filterd.length === 0) {
      setdata(allInitialData);
    } else {
      setdata(filterd);
    }
  };
  //TODO
  const handleSelectStaus = (e) => {
    const list = [...allInitialData];
    switch (e.target.value) {
      case "1":
        const filterDebit = list.filter((el) => el.operation == "debit");
        setdata(filterDebit);
        break;
      case "2":
        const filterCredit = list.filter((el) => el.operation == "credit");
        setdata(filterCredit);
        break;
      default:
        getData();
    }
  };
  const handleDeleteCaisse = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCaisseById(id).then((res) => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          //const newData = allInitialData;
          var removeIndex = allInitialData.filter((el) => {
            return el.id != id;
          });
          setdata(removeIndex);
          setallInitialData(removeIndex);
        });
      }
    });
  };

  const handleSelectDate = (e) => {
    const expr = e.target.value;
    if (expr == 0) {
      getData();
    } else {
      const filterByYear = allInitialData.filter((el) => {
        return el.date.split("-")[0] == expr;
      });
      setdata(filterByYear);
    }
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  const onChangeDate = () => {
 
    getData();
    const filteredDate = currentData.filter((listed) => {
      const momentDate = moment(listed.date);
      if(startDate > endDate){
        error_message(t("Attention La date début doit être inférieur à la date fin !!"))
      }else{
      return (
        momentDate.isSameOrAfter(moment(startDate)) &&
        momentDate.isSameOrBefore(moment(endDate))
      )};
    });
    setcurrentData(filteredDate);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Suivi des entrées et sorties d'espèces")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/ajoutcaisse" className="btn btn-primary mr-3">
                {t("Ajouter caisse")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flexrow">
      <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Total crédits")}</span>
                    <h3 className="mb-0 mt-1 text-primary">{data && sumObjectif(data, "montant_entrant")}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Total Débits")}</span>
                    <h3 className="mb-0 mt-1 text-success">{data && sumObjectif(data, "montant_sortant")}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Solde du mois")}</span>
                    <h3 className="mb-0 mt-1 text-secondary">{data && (sumObjectif(data, "montant_entrant")-sumObjectif(data, "montant_sortant")).toFixed(3)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                          // onChange={(e) => filterByDate(e.target.value)} 
                          // value={dateDebut}
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          //onChange={(e) => setdateDebut(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                          //  onChange={(e) => filterByDate(e.target.value)} 
                          onChange={(e) => setEndDate(e.target.value)}
                          //onChange={(e) => setdateFin(e.target.value)}
                          //value={dateFin}
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Année</option>
                          <option value={1}>{new Date().getFullYear()}</option>
                          <option value={2}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir Statut:</label>

                        <select className="form-control custom-select select2" onChange={handleSelectStaus}>
                          <option value={0}>Tous</option>
                          <option value={1}>En cours</option>
                          <option value={2}>Payée</option>
                          <option value={3}>Partiel</option>
                          <option value={4}>échu</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Date:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" onChange={(e) => filterByDate(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Tous</option>
                          <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                          <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir Statut:</label>

                        <select className="form-control custom-select select2" onChange={handleSelectStaus}>
                          <option value={0}>Tous</option>
                          <option value={1}>Entrée</option>
                          <option value={2}>Sortie</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Liste Caisse")}</h4>

              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("Entrée")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("Sortie")}
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv
                            data={data}
                            name={"List_caisses"}
                            columns={[
                              "facture_code",
                              "client_nom",
                              "montant_total_ht",
                              "montant_total_tva",
                              "montant_total_ttc",
                              "montant_encaissement",
                              "net_a_payer",
                            ]}
                          />
                          <CopieContent />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0">{t("Operation")}</th>
                              <th className="border-bottom-0">{t("Fournisseur / Banque")}</th>
                              <th className="border-bottom-0">{t("Description")}</th>
                              <th className="border-bottom-0">{t("Entrée de Caisse")}</th>
                              <th className="border-bottom-0">{t("Sortie de Caisse")}</th>
                              <th className="border-bottom-0">{t("Date")}</th>

                              <th className="border-bottom-0" style={{ width: 60 }}>
                                {t("Créé à")}
                              </th>
                              <th className="border-bottom-0" style={{ width: 60 }}>
                                {t("Actions")}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData.map((el) => (
                              
                              <tr key={el.id}>
                                {/* {console.log(currentData)} */}
                                <td>{el.Operation}</td>

                                <td>
                                  <Link to={"/admin/clientactivity/" + el.client_code}>
                                    {el.nom} {el.prenom}
                                  </Link>
                                </td>

                                <td>{el.description}</td>

                                <td>
                                  <span className="badge badge-success-light">{el.montant_entrant}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span className="badge badge-danger-light">{el.montant_sortant}</span>
                                </td>
                                <td>{el.date}</td>

                                <td>{el.createdAt}</td>
                                <td>
                                  {true && (
                                    <div className="btn-group">
                                      <button
                                        style={{ textAlign: "center", padding: 2, width: 40 }}
                                        type="button"
                                        className="btn btn-secondary dropdown-toggle dropdown-icon"
                                        data-toggle="dropdown"
                                      ></button>
                                      <div className="dropdown-menu" role="menu">
                                        <Link to={"/admin/caisse/" + el.id} className="dropdown-item">
                                          {t("Editer")}
                                        </Link>
                                        <div className="dropdown-divider" />
                                        <Link to="#" onClick={() => handleDeleteCaisse(el.id)} className="dropdown-item">
                                          {t("Supprimer")}
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ListCaisse;
