import React, { useEffect, useState } from 'react';
import { getCaHtParClient, getStatFacturesParMois } from '../../Services/dashboardService';
import { useTranslation } from 'react-i18next';
import { Cell, PieChart, ResponsiveContainer, Pie, Tooltip, Legend } from 'recharts';

export default function CardClient({ exercice }) {
    const [caParClient, setCaParClient] = useState([]);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D', '#FFCC00', '#A4DE6C', '#D0ED57', '#FFA07A'];
    const GRAY_COLOR = "#E0E0E0";

    const [selectedClient, setSelectedClient] = useState(null);

    const { t } = useTranslation();

    const fetchCaParClient = async () => {
        try {
            const response = await getCaHtParClient(exercice);
            if (response && response.data) {
                setCaParClient(response.data);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du CA par client:", error);
        }
    };

    useEffect(() => {
        fetchCaParClient();
    }, [exercice]);


    const handleClientClick = (name) => {
        setSelectedClient(name === selectedClient ? null : name); // Sélection/déselection
    };


    return (
        <div className='row p-4 bg-white shadow-lg rounded-xl mt-2'>
            <div className="col-8">
                <h3 className="text-xl font-bold mb-4 text-gray-800">CA HT par client</h3>
                <div className="flex justify-center items-center">
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={caParClient}
                                cx="50%"
                                cy="50%"
                                labelLine={true}
                                outerRadius={140}
                                fill="#8884d8"
                                dataKey="value"
                                nameKey="name"
                                label={({ name, value, percent }) => `${name}: ${value}KDT (${(percent * 100).toFixed(2)}%)`}
                                onClick={(e) => handleClientClick(e.name)}
                            >
                                {caParClient.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={selectedClient && entry.name !== selectedClient ? "#E0E0E0" : COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value) => [`${value} KDT`]} />
                            <Legend layout="horizontal" align="center" verticalAlign="bottom" />
                            </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className='col-4'>

                <div className="p-4 bg-white shadow-lg rounded-xl">
                    <h4 className="text-lg font-semibold mb-3 text-gray-700">{t("Détails par client")}</h4>
                    <table
                        className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                        id="hr-table"
                        role="grid"
                        aria-describedby="hr-table_info"
                    >
                        <thead>
                            <tr>
                                <th>{t("Client")}</th>
                                <th>{t("CA HT (KDT)")}</th>
                                <th>{t("Part")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {caParClient?.slice(0, 10).map((client, index) => (
                                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td>{client.name}</td>
                                    <td>{client.value.toFixed(2)}</td>
                                    <td>{client.percentage}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}
