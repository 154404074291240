import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFacturesNonPayees, getFacturesPartiellementPayees, getFacturesPayees } from '../../Services/ComptabledashboardService';

export default function FactureNonPayer({exercice}) {
  const { t } = useTranslation();
  const [factures, setFactures] = useState([]);
  const [totaux, setTotaux] = useState({ totalHT: 0, montantEncaisse: 0, qte: 0 });

  useEffect(() => {

    if(exercice)fetchData(exercice);
  }, [exercice]);
  const fetchData = async (exercice) => {
    const data = await getFacturesNonPayees(exercice);
    setFactures(data?.data?.factures);
  
    setTotaux({ totalHT: data?.data?.totalHT, montantEncaisse: data?.data?.totalMontantEncaisse, qte: data?.data?.rowCount})
   
  };
 


  return (

    <div className="card ">
    <div className="card-header  border-0">
      <h4 className="card-title">{t(" Facture Non Payee")}</h4>
    </div>
    <div className="card-body">
    <div className="p-5  rounded-lg shadow-md mb-6">
        <p className="text-lg font-semibold text-gray-700"><strong>{t("Total HT")}:</strong> {totaux.totalHT.toFixed(2)}</p>
        <p className="text-lg font-semibold text-gray-700"><strong>{t("Quantité Factures")}:</strong> {totaux.qte}</p>
      </div>    </div>
  </div>
   
  );
}

