import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

/**
 * Composant affichant un graphique en barres comparant l'objectif et la réalisation N.
 */
function ObjectifChart2({ objectifs }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(objectifs);
  }, [objectifs]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
      >
        <defs>
          <linearGradient id="colorRealisation" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#0dcd94" stopOpacity={0.9} />
            <stop offset="75%" stopColor="#0dcd94" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorObjectif" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#ffc658" stopOpacity={0.9} />
            <stop offset="75%" stopColor="#ffc658" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        <CartesianGrid opacity={0.1} vertical={false} />
        <XAxis dataKey="mois" angle={-45} textAnchor="end" />
        <YAxis />
        <Tooltip />

        {/* Barre Réalisation N */}
        <Bar dataKey="realisation_N" fill="url(#colorRealisation)" stroke="#0dcd94" />

        {/* Barre Objectif */}
        <Bar dataKey="objectif" fill="url(#colorObjectif)" stroke="#ffc658" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ObjectifChart2;
