import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";
import { toast } from "react-toastify";
const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};
export async function getChequeEmisLigneByCode() {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-ligne/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function deleteChequeEmisLigneById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "cheque-emis-ligne/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getChequeEmisLigneById(id) {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-ligne/cheque-emis-entete/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function findIdLigne(id) {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-ligne/ligne-by-id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function findNumeroCheque() {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-ligne/numero-cheque" , config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function addChequeEmisLigne(date, dateEcheance,fournisseur, montant, numero, type,banque,identet) {
  // creation d'un objet
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    date: date,
    fournisseur_code: fournisseur,
    echeance:dateEcheance,
    montant:montant,
    numero:numero,
    type:type,
    banque:banque,
    user_code:getSession(t("id")),
    cheque_emis_entete_id:identet
  }; 

  try {
    // call api 
    //REACT_APP_API_BASE_URL_V2=http://localhost:3003/api/v2/

    const response = await axios.post(api_url_v2 + "cheque-emis-ligne", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
  
}
export async function updateChequeById(date, dateEcheance, fournisseur, montant, numero, type, banqueCode,id) {
  const credentiel = {
    id: id,
    date: date,
    echeance: dateEcheance,
    fournisseur_code: fournisseur,
    montant: montant,
    numero: numero,
    type: type,
    banque: banqueCode,
    code_generated: getSession("code_generated"),
    user_code:getSession(t("id")),
  };

  try {
    const response = await axios.patch(api_url_v2 + "cheque-emis-ligne", credentiel, config);
    toast.success(t("Chèque modifié"));
    return response;
  } catch (error) {
    if (error.response) {
      toast.error(t("Erreur serveur"));
      console.error("Erreur serveur :", error.response.data);
    } else if (error.request) {
      toast.error(t("Erreur requête"));
      console.error("Erreur requête :", error.request);
    } else {
      console.error(t("Erreur"), error.message);
    }
    return error;
  }
}
